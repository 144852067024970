import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Show,
  Filter,
  Pagination,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  Create,
  Edit,
  SimpleForm,
  BooleanField,
  BooleanInput,
  DateField,
  TextInput,
  required,
  ShowButton,
  PasswordInput,
  DateTimeInput,
  useRecordContext,
  useDataProvider,
  Loading,
  DateInput,
  TabbedForm
} from 'react-admin';
import { ProjectInput, ProjectField } from '../components/projectInput';
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { AutomationInput, AutomationField } from '../components/triggerEditor'

//import { DateTimeInput } from 'react-admin-date-inputs';
//import { makeStyles } from '@material-ui/core/styles';
//import classNames from 'classnames';

const EXPIRING_DAYS = 30;

const licenseColors = {
  valid: 'rgba(158, 255, 143, 0.25) !important',
  expire_soon: 'rgba(255, 239, 99, 0.25) !important',
  expired: 'rgba(255, 99, 99, 0.25) !important',
  disabled: 'rgba(170, 170, 170, 0.25) !important',
};

/*
function getLicenseStyleClass(date, option, disabled = false) {
  const licenseDate = new Date(date);
  const now = new Date();
  const expireSoon = new Date();
  expireSoon.setDate(now.getDate() + EXPIRING_DAYS);

  if (option === 'disabled') {
    return disabled;
  }
  if (option === 'valid') {
    return licenseDate > expireSoon;
  }
  if (option === 'expire_soon') {
    return licenseDate > now && licenseDate < expireSoon;
  }
  if (option === 'expired') {
    return licenseDate < now;
  }

  return false;
}*/

function getLicenseStyleBackground(record)
{
	const expireSoon = new Date();
	const now = new Date();
	expireSoon.setDate(now.getDate() + EXPIRING_DAYS);
	
	const licenseDate = new Date(record['expires']);

	if (!record['enabled'])
		return licenseColors.disabled;
	if (licenseDate > expireSoon)
		return licenseColors.valid;
	if (licenseDate > now && licenseDate < expireSoon)
		return licenseColors.expire_soon;
	return licenseColors.expired;
}

const ColoredDateField = (props) => {
  //const classes = licenseStyles;
  const record = useRecordContext();
  return (
    <DateField
      /*className={classNames({
        [classes.valid]: getLicenseStyleClass(props.record[props.source], 'valid'),
        [classes.expire_soon]: getLicenseStyleClass(props.record[props.source], 'expire_soon'),
        [classes.expired]: getLicenseStyleClass(props.record[props.source], 'expired'),
        [classes.disabled]: getLicenseStyleClass(props.record[props.source], 'disabled', !props.record['enabled']),
      })}*/
	  sx = {{ background: getLicenseStyleBackground(record) }}
	  
      showTime
      {...props}
    />
  );
};

const CredentialFilter = (props) => (
  <Filter {...props}>
    <ProjectInput labelClient="Client (proj.)" source="fk_project" alwaysOn filterMode />
	
	<ReferenceInput label="Client (cred.)" source="fk_client" reference="clients" allowEmpty alwaysOn>
      <SelectInput label="Client (cred.)" optionText="name" />
    </ReferenceInput>
	<TextInput source="__like__username" label="Username" alwaysOn />
	<SelectInput label="Enabled" source="enabled" choices={[
		{id : '1', name : 'Enabled'},
		{id : '0', name : 'Disabled'},
	]}/>
	<DateInput source="expires[$lt]" label="Expires before" />
	<DateInput source="expires[$gt]" label="Expires after" />
  </Filter>
);

const CredentialPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 500]} {...props} />
);

export const CredentialList = (props) => {
	
  return <List
    {...props}
    filters={<CredentialFilter />}
    pagination={<CredentialPagination />}
    perPage={50}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="username" />
	  <ProjectField label="Project" source="fk_project" />
	  <ReferenceField label="Client (cred.)" reference="clients" source="fk_client">
		 <TextField source="name" />
	  </ReferenceField>
      <BooleanField source="enabled" />
      <DateField source="created" />
      <ColoredDateField source="expires" />
      <ShowButton />
    </Datagrid>
  </List>
};

export const CredentialCreate = (props) => (
  <Create {...props} mutationMode="pessimistic" redirect="show">
    <TabbedForm>
		<TabbedForm.Tab label="Basic settings">
		  <ProjectInput label="Project" source="fk_project" />
		  
		  <ReferenceInput reference="clients" source="fk_client"  validate={required()}>
			  <SelectInput label="Client (cred.)" optionText="name" />
		  </ReferenceInput>
		  <TextInput source="username" validate={required()} resettable label="User" />
		  <PasswordInput source="password" validate={required()} label="Password" />
		  <PasswordInput source="password-again" validate={required()} label="Password again" />
		  <DateTimeInput source="expires" label="Expiration" validate={required()} options={{ format: 'dd/MM/yyyy, HH:mm:ss', ampm: false, clearable: true, }} />
		  <BooleanInput source="enabled" initialValue={true} />
		  <TextInput source="userdata" label="User Data" />
		</TabbedForm.Tab>
		
		<TabbedForm.Tab label="Automations">
			<AutomationInput type="credentials" />
		</TabbedForm.Tab>
	</TabbedForm>
  </Create>
);

export const CredentialEdit = (props) => (
  <Edit {...props} mutationMode="pessimistic" redirect="show">
    <TabbedForm>
		<TabbedForm.Tab label="Basic settings">
	  <ProjectInput label="Project" source="fk_project" />
	  
	  <ReferenceInput disabled reference="clients" source="fk_client">
		  <SelectInput label="Client (cred.)" optionText="name" />
	  </ReferenceInput>
	  <TextInput disabled source="username" validate={required()} resettable label="User" />
      <TextInput source="new-password" type="password" label="New password" />
	  <TextInput source="new-password-again" type="password" label="New password again" />
      <DateTimeInput source="expires" label="Expiration" validate={required()} options={{ format: 'dd/MM/yyyy, HH:mm:ss', ampm: false, clearable: true, }} />
      <BooleanInput source="enabled" />
	  <TextInput source="userdata" label="User Data" />
		</TabbedForm.Tab>
			
		<TabbedForm.Tab label="Automations">
			<AutomationInput type="credentials" />
		</TabbedForm.Tab>
	</TabbedForm>
  </Edit>
);

export const CredentialShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
		<Grid container spacing={2} className="hide-sublabel">
			<Grid item md={4}>
				<span className="field-label">Project</span>
				<div className="field-value">
					<ProjectField source="fk_project" />
				</div>
			</Grid>
			
			<Grid item md={4}>
				<span className="field-label">Client (cred.)</span>
				<div className="field-value">
					<ReferenceField label="Client (cred.)" reference="clients" source="fk_client">
						<TextField source="name" />
					</ReferenceField>
				</div>
			</Grid>
			
			<Grid item md={2}>
				<span className="field-label">Enabled</span>
				<BooleanField source="enabled" className="field-value" />
			</Grid>
			
			<Grid item md={2}>
				<span className="field-label">ID</span>
				<TextField source="id" className="field-value" />
			</Grid>
		</Grid>
		
		<Grid container spacing={2} className="hide-sublabel">
			<Grid item md={4}>
				<span className="field-label">User</span>
				<TextField source="username" label="User" className="field-value" />
			</Grid>
			
			<Grid item md={4}>
				<span className="field-label">Created</span>
				<DateField source="created" showTime className="field-value" />
			</Grid>
			
			<Grid item md={4}>
				<span className="field-label">Expires</span>
				<ColoredDateField source="expires" showTime className="field-value" />
			</Grid>
		</Grid>
	  
	  <TextField source="userdata" label="User Data" />
	  
	  <AutomationField label="Automations" type="credentials" />
    </SimpleShowLayout>
  </Show>
);
