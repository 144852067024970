import React from 'react';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Title } from 'react-admin';
import { List, Datagrid, TextField, ResourceContextProvider,
	Button, ListContext, WithListContext, Confirm, useDelete, useUpdate, useDataProvider, Loading, Error} from 'react-admin';
import { useState, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import sw from '../sw.jpg';
import { useRefresh } from 'react-admin';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { grabData } from '../components/projectInput';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment';

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const LicField = (props) => {
	const dataProvider = useDataProvider();
	const [lic, setLic] = useState();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState();
	useEffect(() => {
		dataProvider
			.getList('licenses', {
				pagination: { page: 1, perPage: 1},
				sort: { field: "id", order: "DESC" },
				filter: { identifier: props.identifier },
			})
			.then(({ data }) => {
				if (data.length) {

					dataProvider
						.getList('constants', {
							pagination: { page: 1, perPage: 1 },
							sort: { field: "id", order: "DESC" },
							filter: { key: "TEMPORARY_LICENSE_TAG_ID" },
						})
						.then((response) => {
							if (!data[0].tag_ids.includes(Number(response.data[0]?.value))) {
								setLic(data[0]);
								props.onDetectedLic(data[0]);
							} else { setLic(null); }
						})
						.catch((error) => {
							console.error(error);
							setError(error);
							setLoading(false);
						});

				} else {
					setLic(null);
				}
				
				setLoading(false);

			})
			.catch((error) => {
				console.error(error);
				setError(error);
				setLoading(false);
			});
	}, []);
	return <>
		{loading && <>searching...</>}
		{error && <>error fetching...</>}
		{!loading && !error && lic && <>expires {(new Date(lic.expires)).toLocaleDateString()}</>}
		{!loading && !error && !lic && <>no license yet</>}
	</>
}

const Requests = () => {	

	const [approveOpen, setApproveOpen] = useState(false);
	const [approveVal, setApproveVal] = useState(48);
	const [approveCustomVal, setApproveCustomVal] = useState(moment());
	const [dismissOpen, setDismissOpen] = useState(false);
	const [editedRec, setEditedRec] = useState(null);
	const [detectedLic, setDetectedLic] = useState(false);
	
	const dataProvider = useDataProvider();
	const [clients, setClients] = useState();
	const [products, setProducts] = useState();
	const [projects, setProjects] = useState();
	const [loading1, setLoading1] = useState(true);
	const [loading2, setLoading2] = useState(true);
	const [loading3, setLoading3] = useState(true);
	const [error, setError] = useState();
	
	useEffect(() => { grabData(dataProvider, 'clients', setClients, setLoading1, setError); }, []);	
	useEffect(() => { grabData(dataProvider, 'products', setProducts, setLoading2, setError); }, []);
	useEffect(() => { grabData(dataProvider, 'projects', setProjects, setLoading3, setError); }, []);
	
	const [remove, { isLoading }] = useDelete(
        'requests',
        { id: editedRec?.id }
    );
	
	const [approve, { isLoading2 }] = useUpdate(
        'requests',
		{ id: editedRec?.id, data: { id: editedRec?.id, months: approveVal, approve: true, customDate: approveCustomVal }, previousData: editedRec }
    );
	
	const refresh = useRefresh();
	
	if (loading1 || loading2 || loading3) return <Loading />;
	if (error) return <Error />
	
	return (<><List filter={{handled: 0}} resource="requests" sx={{ ".RaList-content" : { background : 'none' } }}>	
		<WithListContext render={({data}) => {
			const [expanded, setExpanded] = React.useState(null);
			const handleExpandClick = (x) => {
				if (expanded == x)
					setExpanded(null);
				else
					setExpanded(x);
			  };
			return (
				<Stack direction= "row" spacing = { 2} sx = {{ flexWrap: 'wrap', gap: 1 }
		}>
			{ data?.map(record => { 
				let meta = "";
				try { meta = JSON.stringify(JSON.parse(record.meta), null, 2); } catch { meta = record.meta; }
				return <Card key={record.id} sx={{ minWidth: 345, maxWidth: 345, alignSelf: 'flex-start', marginLeft: '0px !important' }}>
			
				<CardMedia				
					sx={{ height: 140 }}
					image={sw}
					title="green iguana"
				/>
				<CardContent>
					<Typography variant="h5" component="div" sx={{marginBottom: "20px"}}>
						Type: {record.type}
					</Typography>
					<Typography variant="body2" color="text.primary">
						ID: {record.name}
					</Typography>
					<Typography variant="body2" color="text.secondary">
						Product: { products.filter(x => x.id == projects.filter(x => x.id == record.fk_project)[0].fk_product)[0]?.name }
					</Typography>
					<Typography variant="body2" color="text.secondary">
						Client: { clients.filter(x => x.id == projects.filter(x => x.id == record.fk_project)[0].fk_client)[0]?.name }
					</Typography>
					<Typography variant = "body2" color = "text.secondary" >
						Requested: { record.created }
					</Typography>
					
					<Collapse in={expanded == record} timeout="auto" unmountOnExit>
					<Typography sx={{ marginTop: "30px"}} variant="body2" color="text.secondary">
						Node: {record.node}
					</Typography>
					<Typography variant="body2" color="text.secondary">
						Network: {record.network}
					</Typography>
					<Typography variant="body2" color="text.secondary" sx={{fontFamily: 'monospace', marginTop: "10px"}}>
					<pre>
						{ meta }
					</pre>
					</Typography>
				</Collapse>
				</CardContent>
				<CardActions disableSpacing>
					<Button label="Approve" variant="contained" startIcon={<CheckIcon />} onClick={() => {
						setDetectedLic(false);
						setEditedRec(record);
						setApproveOpen(true);
					}} />&nbsp;
					<Button label="Dismiss" variant="outlined" startIcon={<CloseIcon />} onClick={() => {
						setDetectedLic(false);
						setEditedRec(record);
						setDismissOpen(true);
					}} />
					<ExpandMore
					  expand={expanded == record}
					  onClick={() => { handleExpandClick(record); }}
					  aria-expanded={expanded}
					  aria-label="show more"
					>
						<ExpandMoreIcon />
					</ExpandMore>
				</CardActions>
			</Card>}
			)}
			</Stack>
		)} }>
		</WithListContext>
	</List>
	<Confirm
		isOpen={approveOpen}
		title={"Do you wish to approve " + editedRec?.name + "?"}
		content={ <>
			<p>Type: {editedRec?.type}</p>
			<p>ID: {editedRec?.name}</p>
			<p>Node: {editedRec?.node}</p>
			<p>Network: {editedRec?.network}</p>
			<br /><Divider /><br />
			<p>Current license: <LicField identifier={editedRec?.name} onDetectedLic={l => { setDetectedLic(l); } } /></p>
			<br /><Divider /><br />
			<RadioGroup
				aria-labelledby="demo-controlled-radio-buttons-group"
				name="controlled-radio-buttons-group"
				value={approveVal}
				onChange={(ev) => { setApproveVal(ev.target.value); }}
			>
			<FormLabel>New license expiration</FormLabel>
			{detectedLic && <FormControlLabel value={-1} control={<Radio />} label="Keep current expiration" />}
			<FormControlLabel value={48} control={<Radio />} label="4 years" />
			<FormControlLabel value={24} control={<Radio />} label="2 years" />
			<FormControlLabel value={12} control={<Radio />} label="1 year" />
			<FormControlLabel value={6} control={<Radio />} label="6 months" />
			<FormControlLabel value={-2} control={<Radio />} label="Custom date" />
			</RadioGroup>
			{
				(approveVal == -2) && <LocalizationProvider dateAdapter={AdapterMoment}>
					<DateTimePicker value={approveCustomVal}
					onChange={(ev) => { setApproveCustomVal(ev); }} label="Custom expiration date" />
				</LocalizationProvider>
			}
		</> }
		onConfirm={() => { approve(); setApproveOpen(false); setTimeout(() => { refresh(); }, 100); }}
		onClose={() => { setApproveOpen(false); }}
		confirm="Approve"
	/>
	<Confirm
		isOpen={dismissOpen}
		title={"Do you wish to dismiss " + editedRec?.name + "?"}
		content={ <><p>Type: {editedRec?.type}</p><p>ID: {editedRec?.name}</p><p>Node: {editedRec?.node}</p><p>Network: {editedRec?.network}</p></> }
		onConfirm={() => { remove(); setDismissOpen(false); }}
		onClose={() => { setDismissOpen(false); }}
		confirm="Dismiss"
	/>
	</>
)}; 

export default Requests;