import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Show,
  Filter,
  Pagination,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  BooleanInput,
  DateField,
  ShowButton,
  useRecordContext,
  TextInput,
  DateInput
} from 'react-admin';
import Grid from '@mui/material/Grid';
import { ProjectField, ProjectInput } from '../components/projectInput';
import { AutomationInput, AutomationField } from '../components/triggerEditor'

const MaybeJsonField = (props) => {
  const record = useRecordContext();
  
  let meta = "";
  try { meta = JSON.stringify(JSON.parse(record[props.source]), null, 2); } catch { meta = record[props.source]; }
  
  return (
    <pre {...props}>{meta}</pre>
  );
};

const LogFilter = (props) => (
   <Filter {...props}>
      <ProjectInput source="fk_project" alwaysOn filterMode />
	  <TextInput source="__like__type" label="Type" alwaysOn />
	  <TextInput source="__like__message" label="Message" alwaysOn />
	  <TextInput source="__like__identity" label="Identity" />
	  <TextInput source="__like__version" label="Version" />
	  <DateInput source="created[$lt]" label="Reported before" />
	<DateInput source="created[$gt]" label="Reported after" />
  </Filter>
);

const LogPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 500]} {...props} />
);

export const LogList = (props) => (
  <List
    {...props}
    filters={<LogFilter />}
    pagination={<LogPagination />}
    perPage={50}
  >
    <Datagrid>
	  <DateField source="created" showTime />
      <ProjectField source="fk_project" label="Project" />
	  <TextField source="identity" />
	  <TextField source="version" />
	  <TextField source="type" />
	  <TextField source="message" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const LogShow = (props) => (
	<Show {...props}>
		<SimpleShowLayout>
		
		<Grid container spacing={2} className="hide-sublabel">
			
			
			<Grid item md={4}>
				<span className="field-label">Project</span>
				<div className="field-value">
					<ProjectField source="fk_project" />
				</div>
			</Grid>
			
			<Grid item md={4}>
				<span className="field-label">Type</span>
				<TextField source="type" className="field-value" />
			</Grid>
			
			<Grid item md={4}>
				<span className="field-label">Version</span>
				<TextField source="version" className="field-value" />
			</Grid>
			
		</Grid>
		
		<Grid container spacing={2} className="hide-sublabel">
		
			<Grid item md={4}>
				<span className="field-label">Created</span>
				<DateField source="created" className="field-value" showTime />
			</Grid>
		
			<Grid item md={4}>
				<span className="field-label">Identity</span>
				<TextField source="identity" className="field-value" />
			</Grid>
			
			<Grid item md={4}>
				<span className="field-label">ID</span>
				<TextField source="id" className="field-value" />
			</Grid>
		</Grid>
		
	  <TextField source="message" />
	  <MaybeJsonField source="data" />	  
	  
	  <AutomationField label="Automations" type="logs" />
	  
		</SimpleShowLayout>
	</Show>
);