import React from 'react';
import { Admin, Resource, fetchUtils, DashboardMenuItem, Menu, MenuItemLink, Layout,
	Authenticated } from 'react-admin';
import { Route } from 'react-router-dom';
import polyglotI18nProvider from 'ra-i18n-polyglot';
//import jsonServerProvider from 'ra-data-json-server';
import englishMessages from 'ra-language-english';
import authProvider from './authProvider';
import { restClient } from 'ra-data-feathers';
import rest from '@feathersjs/rest-client';
import feathers from '@feathersjs/feathers';
import { hooks } from '@feathersjs/hooks';
import authentication from '@feathersjs/authentication-client';
import Settings from './settings';
import LoginPage from './customLogin';
import Category from './category';
import { defaultTheme, CustomRoutes } from 'react-admin';
import { useGetList } from 'react-admin';
import Badge from '@mui/material/Badge';
import { Count } from 'react-admin';
import axios from 'axios';
import { useNotify } from 'react-admin';


// CSS:
import './foradmin-base.css'

// Icons:
import AccountIcon from '@mui/icons-material/AccountBox';
import EventNoteIcon from '@mui/icons-material/EventNote';
import BugReportIcon from '@mui/icons-material/BugReport';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import GroupIcon from '@mui/icons-material/Group';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachFile from '@mui/icons-material/AttachFile';
import InfoIcon from '@mui/icons-material/Info';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import WidgetsIcon from '@mui/icons-material/Widgets';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ShieldIcon from '@mui/icons-material/Shield';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import SellIcon from '@mui/icons-material/Sell';
import BuildIcon from '@mui/icons-material/Build';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmailIcon from '@mui/icons-material/Email';
import FlashAutoIcon from '@mui/icons-material/FlashAuto';

// Pages:
import Documentation from './pages/documentation'
import Dashboard from './pages/dashboard'
import Requests from './pages/requests'

// Resources:
import {
  UserList, UserCreate, UserEdit, UserShow,
} from './pages/users';
import {
  ProjectList, ProjectCreate, ProjectEdit, ProjectShow,
} from './pages/projects';
import {
  ApiKeyList, ApiKeyCreate, ApiKeyEdit, ApiKeyShow,
} from './pages/apiKeys';
import {
  CrashList, CrashShow,
} from './pages/crashes';
import {
  LogList, LogShow,
} from './pages/logs';
import {
  CredentialList, CredentialCreate, CredentialEdit, CredentialShow,
} from './pages/credentials';
import {
  ClientList, ClientCreate, ClientEdit, ClientShow,
} from './pages/clients';
import {
  LicenseList, LicenseCreate, LicenseEdit, LicenseShow,
} from './pages/licenses';
import {
  ProductList, ProductCreate, ProductEdit, ProductShow,
} from './pages/products';
import {
  TagList, TagCreate, TagEdit, TagShow,
} from './pages/tags';
import {
  ConstantList, ConstantEdit, ConstantShow, ConstantCreate
} from './pages/constants';
import {
  NotifBlacklistList, NotifBlacklistEdit, NotifBlacklistShow, NotifBlacklistCreate
} from './pages/notifBlacklist';
import {
  HistoryList, HistoryShow,
} from './pages/history';
import {
  MailTempList, MailTempCreate, MailTempEdit, MailTempShow,
} from './pages/mailTemplates';
import {
  AutomationList, AutomationCreate, AutomationEdit, AutomationShow,
} from './pages/automations';

const messages = {
/*  cs: czechMessages,*/
  en: englishMessages,
};
const i18nProvider = polyglotI18nProvider((locale) => messages[locale], 'en');

/*const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};*/
//const dataProvider = jsonServerProvider(Settings.SERVER, httpClient);
/*const client = feathers();
client.configure(rest('http://localhost:3000', httpClient));*/

const app = feathers();
//app.configure(rest(Settings.SERVER + '/api').fetch(window.fetch));

const api = axios.create(
	{ headers: { 'Authorization': localStorage.getItem('token') } }
);

api.interceptors.request.use(config => ({
  ...config,
  headers: {
    ...config.headers,
	'Authorization': localStorage.getItem('token')
  }
}), null, { synchronous: true })

app.configure(rest(Settings.SERVER + '/api').axios(api));

const dataProvider = restClient(app);

const theme = {
    ...defaultTheme,
    palette: {
        mode: 'dark', // Switching the dark mode on is a single property value change.
    },
};

const MySidebar = props => {
	const { data, total, isLoading, error } = useGetList(
		'requests',
		{ 
			pagination: { page: 1, perPage: 1 },
			sort:{ field: 'id', order: 'DESC' },
			filter: { handled: 0 }
		}
	);	
    return (
		<Menu /*{...props}*/
		sx={{
			'&.RaMenu-closed': {
				marginLeft: "-20px",
				marginRight: "20px",
			},
			'.menu-header': {
				overflow: "visible"
			},
			'&.RaMenu-closed .menu-header': {
				height: 0,
				overflow: "hidden"
			}
		}}
		>
				<Menu.DashboardItem sx={{
					margin: '20px 0px 0px 20px'					
				}} to="/" />
				<Menu.Item sx={{
					margin: '0px 0px 0px 20px'					
				}} to="/requests" primaryText={
					(!isLoading && total > 0) &&
					<Badge badgeContent={<Count resource="requests" filter={{handled: 0}} />} color="error">
						Requests
					</Badge>
					|| <>Requests</>
				} leftIcon={<ChecklistRtlIcon />} />
				
				<Category title="Analysis">
					<Menu.ResourceItem name="crashes" primaryText="Crash Reports" />
					<Menu.ResourceItem name="logs" primaryText="Remote Logs" />
					<Menu.ResourceItem name="history" primaryText="History" />
				</Category>
				
				<Category title="Licencing">
					<Menu.ResourceItem name="credentials" primaryText="Credentials" />
					<Menu.ResourceItem name="licenses" primaryText="Licenses" />
					<Menu.ResourceItem name="tags" primaryText="Tags" />
				</Category>
				
				<Category title="Management">
					<Menu.ResourceItem name="clients" primaryText="Clients" />
					<Menu.ResourceItem name="products" primaryText="Products" />
					<Menu.ResourceItem name="projects" primaryText="Projects" />
				</Category>
				
				<Category title="Settings">
					<Menu.ResourceItem name="automations" primaryText="Automations" />
					<Menu.ResourceItem name="mailtemplates" primaryText="Mail Templates" />
					<Menu.ResourceItem name="apikeys" primaryText="API Keys" />
					<Menu.ResourceItem name="constants" primaryText="System" />
					<Menu.ResourceItem name="notifblacklist" primaryText="Notif. Blacklist" />
					<Menu.ResourceItem name="users" primaryText="Administrators" />
				</Category>
				
				<Category title="Other tools">
					<Menu.Item to="/documentation" primaryText="Documentation" leftIcon={<InfoIcon />}/>
				</Category>
		</Menu>
    );
};

const MyLayout = props => <Layout {...props} sidebar={MySidebar} />

const App = () => {
	return (<>
  <Admin
	theme={theme}
	layout={MyLayout}
	disableTelemetry
	dataProvider={dataProvider}
	i18nProvider={i18nProvider}
	authProvider={authProvider}
	loginPage={LoginPage}
	>
	
	<CustomRoutes>
		<Route exact path="/" element={<Authenticated><Dashboard /></Authenticated>} />
		<Route path="/documentation" element={<Authenticated><Documentation /></Authenticated>} />
		<Route path="/requests" element={<Authenticated><Requests /></Authenticated>} />
	</CustomRoutes>
	
	<Resource name="crashes" options={{ label : 'Crash Reports' }} icon={BugReportIcon}
		list={CrashList} show={CrashShow} />
	<Resource name="logs" options={{ label : 'Remote Logs' }} icon={EventNoteIcon}
		list={LogList} show={LogShow} />	
		
	<Resource name="credentials" options={{ label : 'Credentials' }} icon={GroupIcon}
		list={CredentialList} edit={CredentialEdit} create={CredentialCreate} show={CredentialShow} />	
	<Resource name="licenses" options={{ label : 'Licenses' }} icon={AttachFile}
		list={LicenseList} edit={LicenseEdit} create={LicenseCreate} show={LicenseShow} />
	<Resource name="tags" options={{ label : 'Tags' }} icon={SellIcon}
		list={TagList} edit={TagEdit} create={TagCreate} show={TagShow} />			
		
		
	<Resource name="products" options={{ label : 'Products' }} icon={WidgetsIcon}
		list={ProductList} edit={ProductEdit} create={ProductCreate} show={ProductShow}  />		
	<Resource name="projects" options={{ label : 'Projects' }} icon={AccountTreeIcon}
		list={ProjectList} edit={ProjectEdit} create={ProjectCreate} show={ProjectShow}  />
	<Resource name="clients" options={{ label : 'Clients' }} icon={ContactMailIcon}
		list={ClientList} edit={ClientEdit} create={ClientCreate} show={ClientShow}  />
	
	<Resource name="automations" options={{ label : 'Automations' }} icon={FlashAutoIcon}
		list={AutomationList} edit={AutomationEdit} create={AutomationCreate} show={AutomationShow} />	
	<Resource name="mailtemplates" options={{ label : 'Mail Templates' }} icon={EmailIcon}
		list={MailTempList} edit={MailTempEdit} create={MailTempCreate} show={MailTempShow} />
	<Resource name="apikeys" options={{ label : 'API Keys' }} icon={VpnKeyIcon}
		list={ApiKeyList} edit={ApiKeyEdit} create={ApiKeyCreate} show={ApiKeyShow} />	
	<Resource name="users" options={{ label : 'Users' }} icon={AccountIcon}
		list={UserList} edit={UserEdit} create={UserCreate} show={UserShow} />
	<Resource name="constants" options={{ label : 'Constants' }} icon={BuildIcon}
		list={ConstantList} edit={ConstantEdit} create={ConstantCreate} show={ConstantShow} />
		
	<Resource name="notifblacklist" options={{ label : 'Notif. Blacklist' }} icon={NotificationsOffIcon}
		list={NotifBlacklistList} edit={NotifBlacklistEdit} create={NotifBlacklistCreate} show={NotifBlacklistShow} />
		
	<Resource name="requests" options={{ label : 'Pending requests' }} icon={ChecklistRtlIcon} />
	
	<Resource name="history" options={{ label : 'History' }} icon={AccessTimeIcon}
		list={HistoryList} show={HistoryShow} />
	
  </Admin></>
)};

export default App;
