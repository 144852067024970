import React, { Fragment } from 'react'
import { useCreateSuggestionContext } from 'react-admin';
import { useForm, useFormContext } from "react-hook-form";
import { useState, useEffect, cloneElement, useRef } from 'react';
import { useField } from 'react-final-form';
import Grid from '@mui/material/Grid';
import {
  List,
  Datagrid,
  TextField,
  Show,
  Filter,
  Pagination,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  Create,
  Edit,
  SimpleForm,
  BooleanField,
  BooleanInput,
  DateField,
  TextInput,
  required,
  ShowButton,
  RichTextField,
  TabbedForm,
  SelectField,
  ReferenceArrayInput,
  SimpleFormIterator,
  ArrayInput,
  Button,
  ArrayField,
  WithListContext,
  ListBase,
  SingleFieldList,
  useRecordContext,
  AutocompleteInput,
  Form,
  NumberInput,
  useDataProvider,
  Loading,
  Error,
  CheckboxGroupInput,
  Link
} from 'react-admin';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import AccountIcon from '@mui/icons-material/AccountBox';
import EventNoteIcon from '@mui/icons-material/EventNote';
import BugReportIcon from '@mui/icons-material/BugReport';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import GroupIcon from '@mui/icons-material/Group';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachFile from '@mui/icons-material/AttachFile';
import InfoIcon from '@mui/icons-material/Info';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import WidgetsIcon from '@mui/icons-material/Widgets';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ShieldIcon from '@mui/icons-material/Shield';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import SellIcon from '@mui/icons-material/Sell';
import BuildIcon from '@mui/icons-material/Build';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import EmailIcon from '@mui/icons-material/Email';
import FlashAutoIcon from '@mui/icons-material/FlashAuto';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import ExplicitIcon from '@mui/icons-material/Explicit';


import LabelIcon from '@mui/icons-material/Label';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import LabelOffIcon from '@mui/icons-material/LabelOff';
import CheckIcon from '@mui/icons-material/Check';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import ChatIcon from '@mui/icons-material/Chat';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ClearIcon from '@mui/icons-material/Clear';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import PercentIcon from '@mui/icons-material/Percent';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ReportIcon from '@mui/icons-material/Report';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ChecklistIcon from '@mui/icons-material/Checklist';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WifiPasswordIcon from '@mui/icons-material/WifiPassword';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import DataObjectIcon from '@mui/icons-material/DataObject';
import EventIcon from '@mui/icons-material/Event';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import TodayIcon from '@mui/icons-material/Today';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

import LinkIcon from '@mui/icons-material/Link';

import {TextField as M_TextField, Typography as M_Typography} from '@mui/material';

import {
	Button as M_Button,
    Dialog,
    DialogActions,
	DialogTitle,
    DialogContent
} from '@mui/material';

// ============================================================
// 			CUSTOM COMBINED FIELDS:
// ============================================================

const autoStringOperatorsConditions = [
	{id: 'eq', name: "Equals"},
	{id: 'neq', name: "Not equal"},
	{id: 'sw', name: "Starts with"},
	{id: 'ew', name: "Ends with"},
	{id: 'ct', name: "Contains"}
];

const autoNumberOperatorsConditions = [
	{id: 'eq', name: "Equals"},
	{id: 'neq', name: "Not equal"},
	{id: 'lt', name: "Lesser than"},
	{id: 'lte', name: "Lesser than or equal"},
	{id: 'gt', name: "Greater than"},
	{id: 'gte', name: "Greater than or equal"},
];

const autoTimeExtendChoices = [
	{id: 'd', name: "Days"},
	{id: 'm', name: "Months"},
	{id: 'y', name: "Years"}
];

const autoNumberOperatorsActions = [
	{id: 'eq', name: "Constant number", icon: <EditIcon />, nest: 
		<NumberInput source="value" validate={required()} label='Value' fullWidth /> },
	{id: 'eqv', name: "Variable", icon: <TextRotationNoneIcon />, nest:
		<TextInput source="value" label='Variable' validate={required()} defaultValue='{{var:name_here}}' fullWidth /> },
	{id: 'eqs', name: "System variable", icon: <SettingsSuggestIcon />, nest:
		<SelectInput source="value" label='System variable' validate={required()} fullWidth choices={[
			{id: 'now_date', name: 'Day of month (now: ' + (new Date().getDate()) + ')'},
			{id: 'now_month', name: 'Month of year (now: ' + (new Date().getMonth() + 1) + ')'},
			{id: 'now_year', name: 'Year (now: ' + (new Date().getFullYear() + 1) + ')'},
			{id: 'now_day', name: 'Day of week (Sun..Sat = 0..6) (now: ' + (new Date().getDay()) + ')'},
			{id: 'now_total_month', name: 'Total days this month (now: ' + new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate() + ')'},
			{id: 'last_total_month', name: 'Total days last month (now: ' + new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate() + ')'}
		]} /> },
	{id: 'eqn', name: "Negative value of", icon: <RemoveCircleOutlineIcon />, nest: ()=> <AutoUnaryNum /> },	
	{id: 'add', name: "Add two values", icon: <AddIcon />, nest: ()=> <AutoBinaryNum />},
	{id: 'sub', name: "Subtract two values", icon: <RemoveIcon />, nest: ()=> <AutoBinaryNum />},
	{id: 'mul', name: "Multiply two values", icon: <ClearIcon />, nest: ()=> <AutoBinaryNum />},
	{id: 'div', name: "Divide two values", icon: <HorizontalRuleIcon sx={{transform: "rotate(-45deg)"}} />, nest: ()=> <AutoBinaryNum />},
	{id: 'mod', name: "Modulo of two values", icon: <PercentIcon />, nest: ()=> <AutoBinaryNum />},
	{id: 'rnd', name: "Value rounded", icon: <VerticalAlignCenterIcon />, nest: ()=> <AutoUnaryNum />},
	{id: 'rup', name: "Value rounded up", icon: <VerticalAlignTopIcon />, nest: ()=> <AutoUnaryNum /> },
	{id: 'rdn', name: "Value rounded down", icon: <VerticalAlignBottomIcon/>, nest: ()=> <AutoUnaryNum />},
];

const autoStringOperatorsActions = [
	{id: 'eqi', name: "Interpolated string", icon: <DataObjectIcon />, nest:
		<TextInput multiline source="value" label='Text with optional variables' validate={required()} fullWidth /> },
	{id: 'eq', name: "Constant string", icon: <EditIcon />, nest: 
		<TextInput multiline source="value" label='Value' fullWidth /> },
	{id: 'eqv', name: "Variable", icon: <TextRotationNoneIcon />, nest:
		<TextInput multiline source="value" label='Variable' validate={required()} defaultValue='{{var:name_here}}' fullWidth /> },
	{id: 'con', name: "Concatenate two values", icon: <AddIcon />, nest: ()=> <AutoBinaryStr /> },
	{id: 'ucs', name: "Uppercase of", icon: <VerticalAlignTopIcon />, nest: ()=> <AutoUnaryStr /> },
	{ id: 'lcs', name: "Lowercase of", icon: <VerticalAlignBottomIcon />, nest: () => <AutoUnaryStr /> },
	{ id: 'date', name: "Format as date", icon: <HistoryToggleOffIcon />, nest: () => <AutoDateFormat /> },
];

const AutoUnaryNum = (props) => {
	return <AutoNestedComponent label="Value" {...props} choices={ autoNumberOperatorsActions }
		source={props.source + ".value"} defaultValue={'value'} /> 
}

const AutoBinaryNum = (props) => {
	return <>
	<AutoNestedComponent label="Value 1" {...props} choices={ autoNumberOperatorsActions }
		source={props.source + ".value1"} defaultValue={'value'} />
	<AutoNestedComponent label="Value 2" {...props} choices={ autoNumberOperatorsActions }
		source={props.source + ".value2"} defaultValue={'value'} />
	</>
}

const AutoUnaryStr = (props) => {
	return <AutoNestedComponent label="Value" {...props} choices={ autoStringOperatorsActions }
		source={props.source + ".value"} defaultValue={'value'} /> 
}

const AutoBinaryStr = (props) => {
	return <>
	<AutoNestedComponent label="Value 1" {...props} choices={ autoStringOperatorsActions }
		source={props.source + ".value1"} defaultValue={'value'} />
	<AutoNestedComponent label="Value 2" {...props} choices={ autoStringOperatorsActions }
		source={props.source + ".value2"} defaultValue={'value'} />
	</>
}

const AutoDateFormat = (props) => {
	return <>
		<AutoNestedComponent label="Value" {...props} choices={autoStringOperatorsActions}
			source={props.source + ".value"} defaultValue={'value'} />
		<AutoNestedComponent label="Input format" {...props} choices={autoStringOperatorsActions}
			source={props.source + ".inputformat"} defaultValue={'value'} />
		<AutoNestedComponent label="Output format" {...props} choices={autoStringOperatorsActions}
			source={props.source + ".format"} defaultValue={'value'} />
	</>
}

const AutoIfNum = (props) => {
	return <>
	<TextInput validate={required()} source={ props.source +  ".variable"} label={props.label || 'Variable'} />
	<AutoNumberFilter {...props}>
		<AutoNestedComponent source={props.source +  ".value"} label='Value' choices={autoNumberOperatorsActions} defaultValue={'value'} />
	</AutoNumberFilter></>
}
const AutoIfStr = (props) => {
	return <>
	<TextInput validate={required()} source={ props.source +  ".variable"} label={props.label || 'Variable'} />
	<AutoStringFilter {...props}>
		<AutoNestedComponent source={props.source +  ".value"} label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
	</AutoStringFilter></>
}

const AutoStringFilter = (props) => {
	return (<Grid container spacing={2}>
		<Grid item md={4}>
			<RichSelectInput defaultValue={'eq'} validate={required()} label="Operator" source={props.source + ".operator"} choices={autoStringOperatorsConditions}/>
		</Grid>
		<Grid item md={8}>
			{ cloneElement(props.children, { source: props.source + ".value", label: props.label || 'Value' }) }
		</Grid>
	</Grid>);
}

const AutoNumberFilter = (props) => {
	return (<Grid container spacing={2}>
		<Grid item md={4}>
			<RichSelectInput defaultValue={'eq'} validate={required()} label="Operator" source={props.source + ".operator"} choices={autoNumberOperatorsConditions}/>
		</Grid>
		<Grid item md={8}>
			{ cloneElement(props.children, { source: props.source + ".value", label: props.label || 'Value' }) }
		</Grid>
	</Grid>);
}

const AutoTimeExtendInput = (props) => {
	return (<Grid container spacing={2}>
		<Grid item md={8}>
			<AutoNestedComponent source={ props.source +  ".value"} label='Extend by' choices={autoNumberOperatorsActions} defaultValue={'value'} />
		</Grid>
		<Grid item md={4}>
			<RichSelectInput defaultValue={'m'} validate={required()} label="Option" source={ props.source + ".option" } choices={autoTimeExtendChoices}/>
		</Grid>
	</Grid>);
}

const AutoVarValue = (props) => {
	return (<>
		<TextInput source={ props.source +  ".variable"} label={props.label || 'Variable'} />
	
		<AutoNestedComponent source={props.source + ".value"} {...props} label={props.labelVal || 'Set to'} defaultValue={'value'} />
	</>);
}

const AutoMailPattern = (props) => {	
	return (<Grid container spacing={2}>
	<Grid item md={4}>
		<TextInput fullWidth label="Custom pattern" source={props.source + ".pattern"} validate={required()} />
	</Grid>
	<Grid item md={8}>
		<AutoNestedComponent source={props.source + ".value"} label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
	</Grid>
	</Grid>);
}

const AutoMailAttachment = (props) => {	
	return (<Grid container spacing={2}>
	<Grid item md={4}>
		<TextInput fullWidth label="File name" source={props.source + ".file"} validate={required()} />
	</Grid>
	<Grid item md={8}>
		<AutoNestedComponent source={props.source + ".value"} label='File content' choices={autoStringOperatorsActions} defaultValue={'value'} />
	</Grid>
	</Grid>);
}

const AutoMail = (props) => {
	return (<>
		{typeof (props.showAddress) !== 'undefined' && props.showAddress !== false && <TextInput source={props.source + ".address"} label="Mail address" />}
		<ReferenceInput reference="mailtemplates" source={props.source + ".fk_mailtemplate"}>
			<RichSelectInput validate={required()} label="Mail template" optionText="name" />
		</ReferenceInput>
		<ArrayInput source={props.source + ".attachments"} label="Attachments">
			<SimpleFormIterator className="inline" inline addButton={<Button label="Add attachment"></Button>}
			removeButton={<Button label="Delete"></Button>} disableClear>
				<AutoMailAttachment source="attachment" />
			</SimpleFormIterator>
		</ArrayInput>
	</>);

	/*
	 * This used to be under the ReferenceInput:
	 <ArrayInput source={props.source + ".patterns"} label="Custom replacement patterns">
		<SimpleFormIterator className="inline" inline addButton={<Button label="Add pattern"></Button>}
		removeButton={<Button label="Delete"></Button>} disableClear>
			<AutoMailPattern source="pattern" />
		</SimpleFormIterator>
	</ArrayInput>
	 */

}

// ============================================================
// 			ACTIONS AND CONDITIONS - FIELDS:
// ============================================================

const choicesConditions = (choices, inherited) => {
	let arr = (choices || []).concat(inherited? [] : autoLogicalConditions);
	sortSelect(arr, 'Logical', 'xxx');
	return arr;
}

const choicesActions = (choices, inherited) => {
	let arr = (choices || []).concat(inherited? [] : autoGenericActions);
	sortSelect(arr, 'Context loops', 'Global loops');
	return arr;
}

const sortSelect = (arr, prioCat, unprioCat) => {
	arr.sort((a, b) => {
		if (a.category == b.category)
			return a.name.localeCompare(b.name);
		else
		{
			if (a.category.startsWith(prioCat) || b.category.startsWith(unprioCat))
				return -1;
			if (b.category.startsWith(prioCat) || a.category.startsWith(unprioCat))
				return 1;
			return a.category.localeCompare(b.category);
		}
	});
	
	arr.sort((a, b) => {
		if (a.category.startsWith(prioCat))
			if (b.category.startsWith(prioCat))
				return a.name.localeCompare(b.name);
			else
				return -1;
		else
			if (b.category.startsWith(prioCat))
				return 1;
			else
			{
				if (a.category == b.category)
					return a.name.localeCompare(b.name);
				else
					return a.category.localeCompare(b.category);
			}
	});
}

const AutoAction = (props) => {
	return <AutoNestedComponent label="Action" {...props} 
		choices={
			choicesActions((props.choices || []).concat(autoReconstructGlobalLoops()), props.inheritChoices)
		} />
}

const AutoCondition = (props) => {
	return <AutoNestedComponent label="Condition" {...props} choices={choicesConditions(props.choices, props.inheritChoices)} defaultValue={'true'} />
}

const ConcUniqueID = (arr1, arr2) => {
	const concatenatedArray = (arr1 || []).concat(arr2 || []);

	const uniqueArray = [];
	const seenIDs = new Set();

	for (const obj of concatenatedArray) {
		if (!seenIDs.has(obj.id)) {
			uniqueArray.push(obj);
			seenIDs.add(obj.id);
		}
	}
	return uniqueArray;
}

const AutoComponent = (props) => {
	const cond = ConcUniqueID(props.conditions, props.nestConditions);
	const act = ConcUniqueID(props.actions, props.nestActions);

	return <>
		<AutoCondition {...props} nestActions={act} actions={act} conditions={cond}
			nestConditions={cond} source={props.source + ".condition"} choices={cond} />
		<AutoActions {...props} nestActions={act} actions={act} conditions={cond}
			nestConditions={cond} source={props.source + ".actions"} choices={act} />
	</>
}

const RichSelectInput = (props) => {
	const { inheritChoices, nestActions, nestConditions, ...props2 } = props;
	return <AutocompleteInput {...props2} filterSelectedOptions={false} suggestionLimit={999} groupBy={(option) => option.category} validate={required()} sx={{
		['& .MuiAutocomplete-inputRoot'] : {
			cursor : 'pointer'
		},
		['& .MuiAutocomplete-input'] : {
			caretColor: 'transparent',
			cursor : 'pointer',
			userSelect: 'none'
		},
		['& .MuiAutocomplete-input::selection'] : {
			background: 'transparent'
		},
		['& .MuiAutocomplete-clearIndicator'] : {
			display: 'none'
		}
		
	}} onKeyPress={()=>{return;}} optionText={props.optionText || (option => {
		return (<span className="auto-option">{option.icon}&nbsp;{option.name}</span>)
	}) } inputText={option => (typeof(props.optionText) === 'string')? option[props.optionText] : option.name}
	/>	
}

const AutoNestedComponent = (props) => {
	const formContext = useFormContext();
	let v = formContext.getValues(props.source + ".type");	
	const [nval, setNVal] = useState(v || props.defaultValue);
	
	let child = null;
	if (nval !== null)
		child = props.choices.filter((x) => x.id == nval);

	let ico = null;
	if (child && child.length) {
		child = child[0];		
		ico = child.icon
	}
	if (child && child.nest)
	{
		child = child.nest;
		if (typeof(child) === 'function')
			child = child(); // We do this to allow (emulate) infinite JSX cross references at the time of declaration - we need to break those cycles and use a function sometimes.
	}
	else child = null;
	
	useEffect(() => {
		if (nval && v != nval) {
			formContext.setValue(props.source + ".type", nval);
			/*
				This is a bugfix for when you add entity for ArrayInput, then remove it and add it again.
				The context gets a bit fucky and the default value is not used for some reason. It only
				happens in conditions, not actions, because those are nested slightly differently and they work.
			*/
		}
	});
	
	let childObj = {source: props.source + '.data' };
	if (child && child.props.inheritChoices) {
		childObj.choices = props.choices;
		childObj.inheritChoices = true;
	}
	
	if (typeof(props.actions) !== 'undefined' && typeof(props.conditions) != 'undefined')
	{
		childObj.nestActions = props.actions
		childObj.nestConditions = props.conditions;
	}
	else
	{
		if (typeof(props.nestActions) !== 'undefined' && typeof(props.nestConditions) != 'undefined')
		{
			childObj.nestActions = props.nestActions
			childObj.nestConditions = props.nestConditions;
		}
		
		if (child && child.props.nested)
		{
			childObj.actions = props.nestActions;
			childObj.conditions = props.nestConditions;
		}
	}
	
	let childElem = null;
	if (child)
		childElem = cloneElement(child, childObj);
	
	const {inheritChoices, ...props2} = props;	
		
	return (<><RichSelectInput {...props2}
		defaultValue={ props2.defaultValue }
		source={props2.source + ".type"}
		label={ props2.label}
		choices={props2.choices}
		
		onChange={(val) => { setNVal(val); }}
	/>	
		{child && (<div className="auto-editor-subform">
		<span className="auto-editor-subicon">{ico && cloneElement(ico, {fontSize: 'small'})}</span>
		{
			childElem
		}
	</div>) }
	</>);
}

const AutoConditions = (props) => {
	const { inheritChoices, nestActions, nestConditions, ...props2 } = props;
	return (<ArrayInput {...props2} label="Conditions">
		<SimpleFormIterator 
			addButton={<Button label="Add condition"></Button>}
			removeButton={<Button label="Delete"></Button>}
			disableClear
			>
				<AutoCondition source="conditions" choices={props2.choices} inheritChoices={inheritChoices} />
		</SimpleFormIterator>
	</ArrayInput>);
}

const AutoActions = (props) => {
	const { inheritChoices, nestActions, nestConditions, ...props2 } = props;
	/*if (props2.actions) props2.nestActions = props2.actions;
	if (props2.conditions) props2.nestConditions = props2.conditions;*/
	return (<ArrayInput {...props2} label="Actions">
		<SimpleFormIterator 
			addButton={<Button label="Add action"></Button>}
			removeButton={<Button label="Delete"></Button>}
			disableClear
			>
			<AutoAction source="action" choices={props2.choices} nestConditions={nestConditions || props2.conditions} nestActions={nestActions || props2.actions} />
		</SimpleFormIterator>
	</ArrayInput>)
}

// ============================================================
// 			ACTIONS AND CONDITIONS - CONFIG:
// ============================================================

const autoEnDis = [ {id : 1, name : 'Enabled'},	{id : 0, name : 'Disabled'} ];

const autoLogicalConditions = [
	{ id: 'true', category: 'Logical', name: 'No condition (always true)', 
		icon: <CheckIcon />},
	{ id: 'not', category: 'Logical', name: 'If not...', 
		icon: <LabelOffIcon />, nest: <AutoCondition source="data" inheritChoices={true} /> },
	{ id: 'or', category: 'Logical', name: 'At least one of...' ,
		icon: <LabelImportantIcon />, nest: <AutoConditions source="data" inheritChoices={true} />},
	{ id: 'and', category: 'Logical', name: 'All of...' ,
		icon: <LabelIcon />, nest: <AutoConditions source="data" inheritChoices={true} />},
	{ id: 'if.num', category: 'Logical', name: 'If number variable' ,
		icon: <LooksOneIcon />, nest: <AutoIfNum />},
	{ id: 'if.str', category: 'Logical', name: 'If string variable' ,
		icon: <ExplicitIcon />, nest: <AutoIfStr />  },
]

const autoGenericActions = [
	{ id: 'generic.comment', category: 'Comment', name: 'Comment',
		icon: <ChatBubbleIcon />, nest: <TextInput multiline source="value" label='Comment' fullWidth className="comment" />},	
	{ id: 'generic.mail', category: 'Comunication', name: 'Send e-mail to specific address',
		icon: <MailOutlineIcon />, nest: <AutoMail showAddress />},	
	{ id: 'generic.var.num', category: 'Logical', name: 'Set number variable',
		icon: <LooksOneIcon />, nest: <AutoVarValue choices={ autoNumberOperatorsActions } /> },
	{ id: 'generic.var.str', category: 'Logical', name: 'Set text variable',
		icon: <ExplicitIcon />, nest: <AutoVarValue choices={ autoStringOperatorsActions } /> },
	{ id: 'generic.if', category: 'Logical', name: 'Nest condition',
		icon: <SubdirectoryArrowRightIcon />, nest: <AutoComponent nested />},
	{ id: 'generic.while', category: 'Logical', name: 'Nest custom loop',
		icon: <AllInclusiveIcon />, nest: <AutoComponent nested /> },
];

const autoReconstructGlobalLoops = () => {
	let arr = [];
	for (let ent in entityOptions)
	{
		arr.push({
			id: 'generic.loop.' + entityOptions[ent].id,
			name: 'Loop all: ' + entityOptions[ent].name + '...',
			category: 'Global loops',
			icon: entityOptions[ent].icon,
			nest: entityOptions[ent].nest
		});
	}
	return arr;
}

// ------------------ SPECIFIC CONDITIONS ------------------

const autoLicenseConditions = [
	{ id: 'licenses.tag', category: 'License conditions', name: 'License tag',
		icon: <LocalOfferIcon />, nest: <ReferenceInput reference="tags" source="fk_tag">
				<RichSelectInput label="License tagged with" validate={required()} optionText="tag" />
			</ReferenceInput> },
	{ id: 'licenses.identifier', category: 'License conditions', name: 'License identifier',
		icon: <Grid3x3Icon />, nest: <AutoStringFilter>
				<AutoNestedComponent source="value" label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
			</AutoStringFilter>	 },
	{ id: 'licenses.hwidentity', category: 'License conditions', name: 'License hardware identity',
		icon: <SettingsApplicationsIcon />, nest: <AutoStringFilter>
				<AutoNestedComponent source="value" label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
			</AutoStringFilter>	 },
	{ id: 'licenses.enabled', category: 'License conditions', name: 'License state',
		icon: <PowerSettingsNewIcon />, nest: <RichSelectInput defaultValue={1}  validate={required()} label="Enabled" source="value" choices={autoEnDis} /> },
	{ id: 'licenses.expires', category: 'License conditions', name: 'License expiration (in days)',
		icon: <EventBusyIcon />, nest: <AutoNumberFilter>
				<AutoNestedComponent source="value" label='Value' choices={autoNumberOperatorsActions} defaultValue={'value'} />
			</AutoNumberFilter>	 },
	{ id: 'licenses.extended', category: 'License conditions', name: 'License extended (days in past)',
	icon: <EventRepeatIcon />, nest: <AutoNumberFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoNumberOperatorsActions} defaultValue={'value'} />
		</AutoNumberFilter>	 },
	{ id: 'licenses.created', category: 'License conditions', name: 'License created (days in past)',
	icon: <TodayIcon />, nest: <AutoNumberFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoNumberOperatorsActions} defaultValue={'value'} />
		</AutoNumberFilter>	 }
];

const autoClientConditions = [
	{ id: 'clients.name', category: 'Client conditions', name: 'Client name',
	icon: <Grid3x3Icon />, nest: <AutoStringFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
		</AutoStringFilter>	 },
	{ id: 'clients.email', category: 'Client conditions', name: 'Client e-mail',
	icon: <AlternateEmailIcon />, nest: <AutoStringFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
		</AutoStringFilter>	 },
];

const autoProductConditions = [
	{ id: 'products.name', category: 'Product conditions', name: 'Product name',
	icon: <Grid3x3Icon />, nest: <AutoStringFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
		</AutoStringFilter>	 },
	{ id: 'products.enabled', category: 'Product conditions', name: 'Product state',
		icon: <PowerSettingsNewIcon />, nest: <RichSelectInput defaultValue={1}  validate={required()} label="Enabled" source="value" choices={autoEnDis} /> },
];

const autoApiKeyConditions = [
	{ id: 'apikeys.expires', category: 'Api key conditions', name: 'Api key expiration (in days)',
		icon: <DateRangeIcon />, nest: <AutoNumberFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoNumberOperatorsActions} defaultValue={'value'} />
		</AutoNumberFilter>	 }
];

const autoCrashConditions = [
	{ id: 'crashes.identity', category: 'Crash report conditions', name: 'Crash report identity',
	icon: <AlternateEmailIcon />, nest: <AutoStringFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
		</AutoStringFilter>	 },
	{ id: 'crashes.version', category: 'Crash report conditions', name: 'Crash report version',
	icon: <Grid3x3Icon />, nest: <AutoStringFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
		</AutoStringFilter>	 },
	{ id: 'crashes.type', category: 'Crash report conditions', name: 'Crash report type',
	icon: <WorkspacesIcon />, nest: <AutoStringFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
		</AutoStringFilter>	 },
	{ id: 'crashes.error', category: 'Crash report conditions', name: 'Crash report error',
	icon: <ReportIcon />, nest: <AutoStringFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
		</AutoStringFilter>	 },
	{ id: 'crashes.detail', category: 'Crash report conditions', name: 'Crash report detail',
	icon: <FormatListBulletedIcon />, nest: <AutoStringFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
		</AutoStringFilter>	 },
	{ id: 'crashes.config', category: 'Crash report conditions', name: 'Crash report config',
	icon: <ChecklistIcon />, nest: <AutoStringFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
		</AutoStringFilter>	 },
	{ id: 'crashes.description', category: 'Crash report conditions', name: 'Crash report description',
	icon: <EditNoteIcon />, nest: <AutoStringFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
		</AutoStringFilter>	 },
];

const autoLogConditions = [
	{ id: 'logs.identity', category: 'Log conditions', name: 'Log identity',
	icon: <AlternateEmailIcon />, nest: <AutoStringFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
		</AutoStringFilter>	 },
	{ id: 'logs.version', category: 'Log conditions', name: 'Log version',
	icon: <Grid3x3Icon />, nest: <AutoStringFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
		</AutoStringFilter>	 },
	{ id: 'logs.type', category: 'Log conditions', name: 'Log type',
	icon: <WorkspacesIcon />, nest: <AutoStringFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
		</AutoStringFilter>	 },
	{ id: 'logs.message', category: 'Log conditions', name: 'Log message',
	icon: <ReportIcon />, nest: <AutoStringFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
		</AutoStringFilter>	 },
	{ id: 'logs.data', category: 'Log conditions', name: 'Log data',
	icon: <FormatListBulletedIcon />, nest: <AutoStringFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
		</AutoStringFilter>	 },
];

const autoCredentialConditions = [
	{ id: 'credentials.username', category: 'Credential conditions', name: 'Credential username',
	icon: <AlternateEmailIcon />, nest: <AutoStringFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
		</AutoStringFilter>	 },
	{ id: 'credentials.userdata', category: 'Credential conditions', name: 'Credential user data',
	icon: <FormatListBulletedIcon />, nest: <AutoStringFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
		</AutoStringFilter>	 },
	{ id: 'credentials.expires', category: 'Credential conditions', name: 'Credential expiration (in days)',
		icon: <DateRangeIcon />, nest: <AutoNumberFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoNumberOperatorsActions} defaultValue={'value'} />
		</AutoNumberFilter>	 },
	{ id: 'credentials.enabled', category: 'Credential conditions', name: 'Credential state',
		icon: <PowerSettingsNewIcon />, nest: <RichSelectInput defaultValue={1}  validate={required()} label="Enabled" source="value" choices={autoEnDis} /> },
];

const autoRequestConditions = [
	{ id: 'requests.enabled', category: 'Pending request conditions', name: 'Pending request state',
		icon: <PowerSettingsNewIcon />, nest: <RichSelectInput defaultValue={1}  validate={required()} label="Enabled" source="value" choices={autoEnDis} /> },
	{ id: 'requests.handled', category: 'Pending request conditions', name: 'Pending request handled',
		icon: <CheckCircleIcon />, nest: <RichSelectInput defaultValue={0}  validate={required()} label="Handled" source="value" choices={[ {id : 1, name : 'Already handled'},	{id : 0, name : 'Still pending'} ]} /> },
	{ id: 'requests.name', category: 'Pending request conditions', name: 'Pending request name',
	icon: <AlternateEmailIcon />, nest: <AutoStringFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
		</AutoStringFilter>	 },
	{ id: 'requests.type', category: 'Pending request conditions', name: 'Pending request type',
	icon: <WorkspacesIcon />, nest: <AutoStringFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
		</AutoStringFilter>	 },
	{ id: 'requests.node', category: 'Pending request conditions', name: 'Pending request node',
	icon: <Grid3x3Icon />, nest: <AutoStringFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
		</AutoStringFilter>	 },
	{ id: 'requests.network', category: 'Pending request conditions', name: 'Pending request network',
	icon: <WifiPasswordIcon />, nest: <AutoStringFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
		</AutoStringFilter>	 },
];

const autoProjectConditions = [
	{ id: 'projects.name', category: 'Project conditions', name: 'Project name',
	icon: <Grid3x3Icon />, nest: <AutoStringFilter>
			<AutoNestedComponent source="value" label='Value' choices={autoStringOperatorsActions} defaultValue={'value'} />
		</AutoStringFilter>	 },
	{ id: 'projects.enabled', category: 'Project conditions', name: 'Project state',
		icon: <PowerSettingsNewIcon />, nest: <RichSelectInput defaultValue={1}  validate={required()} label="Enabled" source="value" choices={autoEnDis} /> },
];

// ------------------ SPECIFIC ACTIONS ------------------
let autoLicenseActions = [
	/* COMMUNICATION */
	{ id: 'licenses.mail', category: 'Comunication', name: 'Send e-mail to project\'s client (link from license)',
		icon: <EmailIcon />, nest: <AutoMail />},
	{ id: 'licenses.chat', category: 'Comunication', name: 'Write to project\'s rocket.chat (link from license)',
		icon: <ChatIcon />, nest: <AutoNestedComponent source="message" label='Message' choices={autoStringOperatorsActions} defaultValue={'value'} />},
		
	/* FOREIGN KEYS */
	{ id: 'licenses.foreign.projects', category: 'Context switch', name: 'License parent: Project...', icon: <AccountTreeIcon />,
		nest: ()=> <AutoComponent conditions={autoProjectConditions} actions={autoProjectActions} /> },
		
	/* LICENSE ACTIONS */
	{ id: 'licenses.extend', category: 'License actions', name: 'Extend license' ,
		icon: <DateRangeIcon />, nest: <AutoTimeExtendInput />},
	{ id: 'licenses.tag.add', category: 'License actions', name: 'Add tag to license',
		icon: <TurnedInIcon />, nest: <ReferenceInput reference="tags" source="fk_tag">
				<RichSelectInput label="Add tag" optionText="tag" validate={required()} />
			</ReferenceInput>},
	{ id: 'licenses.tag.remove', category: 'License actions', name: 'Remove tag from license',
		icon: <TurnedInNotIcon />, nest: <ReferenceInput reference="tags" source="fk_tag">
				<RichSelectInput label="Remove tag" optionText="tag" validate={required()} />
			</ReferenceInput> },
	{ id: 'licenses.enabled', category: 'License actions', name: 'Set license state',
		icon: <PowerSettingsNewIcon />, nest: <RichSelectInput defaultValue={1}  validate={required()} label="Enabled" source="value" choices={autoEnDis} />},

];

const autoProjectActions = [
	/* COMMUNICATION */
	{ id: 'projects.mail.clients', category: 'Comunication', name: 'Send e-mail to project\'s client',
		icon: <EmailIcon />, nest: <AutoMail />},
	{ id: 'projects.chat', category: 'Comunication', name: 'Write to project\'s rocket.chat',
		icon: <ChatIcon />, nest: <AutoNestedComponent source="message" label='Message' choices={autoStringOperatorsActions} defaultValue={'value'} />},
	
	/* FOREIGN KEYS */
	{ id: 'projects.foreign.clients', category: 'Context switch', name: 'Project parent: Client...', icon: <ContactMailIcon />,
		nest: ()=> <AutoComponent conditions={autoClientConditions} actions={autoClientActions} /> },
	{ id: 'projects.foreign.products', category: 'Context switch', name: 'Project parent: Product...', icon: <WidgetsIcon />,
		nest: ()=> <AutoComponent conditions={autoProductConditions} actions={autoProductActions} /> },
	
	/* LOOPS */
	{ id: 'projects.loop.apikeys', category: 'Context loops', name: 'Project loop: Api Keys...', icon: <VpnKeyIcon />,
		nest: <AutoComponent conditions={[]} actions={[]} /> },
	{ id: 'projects.loop.crashes', category: 'Context loops', name: 'Project loop: Crash Reports...', icon: <BugReportIcon />,
		nest: <AutoComponent conditions={[]} actions={[]} /> },
	{ id: 'projects.loop.logs', category: 'Context loops', name: 'Project loop: Remote Logs...', icon: <EventNoteIcon />,
		nest: <AutoComponent conditions={[]} actions={[]} /> },
	{ id: 'projects.loop.credentials', category: 'Context loops', name: 'Project loop: Credentials...', icon: <GroupIcon />,
		nest: <AutoComponent conditions={[]} actions={[]} /> },
	{ id: 'projects.loop.licenses', category: 'Context loops', name: 'Project loop: Licenses...', icon: <AttachFile />,
		nest: <AutoComponent conditions={autoLicenseConditions} actions={autoLicenseActions} /> },
	{ id: 'projects.loop.requests', category: 'Context loops', name: 'Project loop: Pending Requests...', icon: <ChecklistRtlIcon />,
		nest: <AutoComponent conditions={[]} actions={[]} /> },
];

const autoClientActions = [
	/* COMMUNICATION */
	{ id: 'clients.mail', category: 'Comunication', name: 'Send e-mail to client',
		icon: <EmailIcon />, nest: <AutoMail />},
	
	/* LOOPS */
	{ id: 'clients.loop.credentials', category: 'Context loops', name: 'Client loop: Credentials...', icon: <GroupIcon />,
		nest: <AutoComponent conditions={[]} actions={[]} /> },
	{ id: 'clients.loop.projects', category: 'Context loops', name: 'Client loop: Projects...', icon: <AccountTreeIcon />,
		nest: <AutoComponent conditions={autoProjectConditions} actions={autoProjectActions} /> },
];

const autoLicenseTagsActions = [	
	/* FOREIGN KEYS */
	{ id: 'licensestags.foreign.licenses', category: 'Context switch', name: 'License-tags (M:N link) parent: License...', icon: <AttachFile />,
		nest: ()=> <AutoComponent conditions={autoLicenseConditions} actions={autoLicenseActions} /> },
	{ id: 'licensestags.foreign.tags', category: 'Context switch', name: 'License-tags (M:N link) parent: Tag...', icon: <TurnedInIcon />,
		nest: ()=> <AutoComponent conditions={[]} actions={autoTagsActions} /> },
];

autoLicenseActions.push( // Cyclical reference, we need to do this after the autoLicenseTagsActions declaration.
	/* LOOPS */
	{ id: 'licenses.loop.licensestags', category: 'Context loops', name: 'License loop: License-tags (M:N link)...', icon: <LinkIcon />,
	nest: <AutoComponent conditions={[]} actions={autoLicenseTagsActions} /> }
);

const autoTagsActions = [
	
	/* LOOPS */
	{ id: 'tags.loop.licensestags', category: 'Context loops', name: 'Tag loop: License-tag link (M:N)...', icon: <AccountTreeIcon />,
		nest: <AutoComponent conditions={[]} actions={autoLicenseTagsActions} /> },	
];

const autoCredentialActions = [	
	/* FOREIGN KEYS */
	{ id: 'credentials.foreign.clients', category: 'Context switch', name: 'Credential parent: Client (specific)...', icon: <ContactMailIcon />,
		nest: ()=> <AutoComponent conditions={autoClientConditions} actions={autoClientActions} /> },
	{ id: 'credentials.foreign.projects', category: 'Context switch', name: 'Credential parent: Project...', icon: <AccountTreeIcon />,
		nest: ()=> <AutoComponent conditions={autoProjectConditions} actions={autoProjectActions} /> },
];

const autoProductActions = [
	
	/* LOOPS */
	{ id: 'products.loop.projects', category: 'Context loops', name: 'Product loop: Projects...', icon: <AccountTreeIcon />,
		nest: <AutoComponent conditions={autoProjectConditions} actions={autoProjectActions} /> },	
];

const autoApiKeyActions = [	
	/* FOREIGN KEYS */
	{ id: 'apikeys.foreign.projects', category: 'Context switch', name: 'Api key parent: Project...', icon: <AccountTreeIcon />,
		nest: ()=> <AutoComponent conditions={autoProjectConditions} actions={autoProjectActions} /> },
];

const autoCrashActions = [	
	/* FOREIGN KEYS */
	{ id: 'crashes.foreign.projects', category: 'Context switch', name: 'Crash report parent: Project...', icon: <AccountTreeIcon />,
		nest: ()=> <AutoComponent conditions={autoProjectConditions} actions={autoProjectActions} /> },
];

const autoLogActions = [	
	/* FOREIGN KEYS */
	{ id: 'logs.foreign.projects', category: 'Context switch', name: 'Log parent: Project...', icon: <AccountTreeIcon />,
		nest: ()=> <AutoComponent conditions={autoProjectConditions} actions={autoProjectActions} /> },
];

const autoRequestActions = [	
	/* FOREIGN KEYS */
	{ id: 'requests.foreign.projects', category: 'Context switch', name: 'Pending request parent: Project...', icon: <AccountTreeIcon />,
		nest: ()=> <AutoComponent conditions={autoProjectConditions} actions={autoProjectActions} /> },
];

// ------------------ ------------------ ------------------

const entityOptions = [
	{id: 'apikeys', name: "Api Keys", icon: <VpnKeyIcon />, nest:
		<AutoComponent conditions={autoApiKeyConditions} actions={autoApiKeyActions} /> },
	{id: 'clients', name: "Clients", icon: <ContactMailIcon />, nest:
		<AutoComponent conditions={autoClientConditions} actions={autoClientActions} /> },
	{id: 'crashes', name: "Crash Reports", icon: <BugReportIcon />, nest:
		<AutoComponent conditions={autoCrashConditions} actions={autoCrashActions} /> },
	{id: 'credentials', name: "Credentials", icon: <GroupIcon />, nest:
		<AutoComponent conditions={autoCredentialConditions} actions={autoCredentialActions} /> },
	{id: 'licenses', name: "Licenses", icon: <AttachFile />, nest:
		<AutoComponent conditions={autoLicenseConditions} actions={autoLicenseActions} /> },
	{id: 'logs', name: "Logs", icon: <EventNoteIcon />, nest:
		<AutoComponent conditions={autoLogConditions} actions={autoLogActions} /> },
	{id: 'requests', name: "Pending Requests", icon: <ChecklistRtlIcon />, nest:
		<AutoComponent conditions={autoRequestConditions} actions={autoRequestActions} /> },
	{id: 'products', name: "Products", icon: <WidgetsIcon />, nest:
		<AutoComponent conditions={autoProductConditions} actions={autoProductActions} /> },
	{id: 'projects', name: "Projects", icon: <AccountTreeIcon />, nest: 
		<AutoComponent conditions={autoProjectConditions} actions={autoProjectActions} /> },
	{id: 'licensestags', name: "License-tag links (M:N)", icon: <LinkIcon />, nest: 
		<AutoComponent conditions={[]} actions={autoLicenseTagsActions} /> },
	{id: 'tags', name: "Tags", icon: <TurnedInIcon />, nest:
		<AutoComponent conditions={[]} actions={autoTagsActions} /> },
]

// ============================================================
// 			AUTOEDITOR:
// ============================================================

const autoTriggers = [
	{id: 'daily', name: 'Run every day for selected only', icon: <AccessTimeIcon />},
	{id: 'weekly', name: 'Run every week for selected only', icon: <DateRangeOutlinedIcon/>},
	{id: 'monthly', name: 'Run every month for selected only', icon: <EventOutlinedIcon/>},
	{id: 'daily-all', name: 'Run every day for all', icon: <AccessTimeFilledIcon/>},
	{id: 'weekly-all', name: 'Run every week for all', icon: <DateRangeIcon/>},
	{id: 'monthly-all', name: 'Run every month for all', icon: <EventIcon/>},
	{id: 'created', name: 'When entity is created', icon: <AddIcon/>},
	{id: 'edited', name: 'When entity is edited', icon: <EditIcon/>},
	{id: 'deleted', name: 'When entity is deleted', icon: <RemoveIcon/>},
];

export const automationData = (type) => {
	let dataProvider = useDataProvider();
	const record = useRecordContext();
	const formContext = useFormContext();
	
	const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
	
	const [dataCtx, setDataCtx] = useState();
    const [loading2, setLoading2] = useState(true);
    const [error2, setError2] = useState();
	
	useEffect(() => {
        dataProvider.getList('automations', {
			pagination: { page: 1, perPage: 10000 },
			sort: { field: "id", order: "DESC" },
			filter: {enabled: 1},
		  })
            .then(({ data }) => {
                setData(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, []);
	
	useEffect(() => {
		if (record && record.id) // Edit, not create
			dataProvider.getList('automationscontexts', {
				pagination: { page: 1, perPage: 10000 },
				sort: { field: "id", order: "DESC" },
				filter: { entity_id: record.id },
			  })
				.then(({ data }) => {
					setDataCtx(data);
					setLoading2(false);
				})
				.catch(error => {
					setError2(error);
					setLoading2(false);
				})
		else {
			setDataCtx([]);
			setLoading2(false);
		}
    }, []);
	
	let arr = [];
	let arrOn = [];
	let arrOthers = [];
	
	if (data && dataCtx)
		for (let i = 0; i < data.length; i++) {
			// data are stored as a string but the BE already parses them for us into a JSON format.
			// We don't need to do it here.
			if (data[i].data.type == type) {
				if (data[i].data.trigger.toLowerCase() == 'daily' ||
					data[i].data.trigger.toLowerCase() == 'weekly' ||
					data[i].data.trigger.toLowerCase() == 'monthly') {
					arr.push(data[i]);
					let maybe = dataCtx.filter(x => x.automation_id == data[i].id);
					if (maybe.length) {
						arrOn.push(data[i].id);
					}
				} else {
					arrOthers.push(data[i]);
				}
			}
		}
	
	useEffect(() => {
		if (formContext)
			formContext.setValue('automationscontexts', arrOn);
	}, [data, dataCtx]);
	
	return { choices: arr, loading: loading || loading2, error: error || error2,
		selected: arrOn.map(on => arr.filter(x => x.id == on)[0]),
		others: arrOthers };
}

export const AutomationField = (props) => {
	let auto = automationData(props.type);
	
	let makeSuffix = (x) => {
	
		let suffix = x.data.trigger;

		if (x.data.trigger == 'edited' || x.data.trigger == 'deleted')
			suffix = 'when ' + x.data.type + ' are ' + x.data.trigger;
		
		if (x.data.trigger.endsWith('-all'))
			suffix = x.data.trigger.replace('-all', '') + ' for all ' + x.data.type;
		
		return suffix;
	}
	
	return <Stack direction="row" spacing={1}>
		{ auto.selected.concat(auto.others).filter(x => x.data.trigger != 'created').map(x => 
			<Link to={"/automations/" + x.id}><Chip label={<><span>{x.name}</span> <i style={{opacity: 0.7}}>
				{ '(' + makeSuffix(x) + ')' }
			</i></>} /></Link>
		) }
    </Stack>
}

export const AutomationInput = (props) => {
	
	let auto = automationData(props.type);
	let arr = auto.choices;
	
	if (auto.loading) return <Loading />;
    if (auto.error) return <Error />;
	
	const NoData = (props) => { return (<div>There are no automations available for entity type: <i>{props.type}</i>.</div>) };
	const Lnk = (props) => { return (<div style={{ display: "flex", justifyContent: 'flex-end', width: '100%' }}><Link to="/automations" target="_blank"><M_Button variant="outlined">Edit automations</M_Button></Link></div>) };
	
	//if (!data || !data.length) return (<><Lnk /><NoData {...props} /></>);
	
	if (!arr || !arr.length) return (<><Lnk /><NoData {...props} /></>);
	
	return (<><Lnk /><CheckboxGroupInput row={false} label={"Enabled automations for this entity of type: "+props.type+""} source="automationscontexts" choices={arr} optionText={(record) => {
		
		return <><span><b>{record.name}</b></span><div style={{paddingLeft: '30px'}} dangerouslySetInnerHTML={{__html: record.description}}>
		</div></>;
	}} sx={{ '& .MuiFormControlLabel-root': { borderBottom: '1px solid rgba(255,255,255,0.35)', padding: '10px' },
			'& .MuiFormControlLabel-root:nth-of-type(1)': { borderTop: '1px solid rgba(255,255,255,0.35)', marginTop: '10px' } }} /></>);
}

const TriggerEditor = (props) => {
	
	const [openExport, setOpenExport] = useState(false);
	const [openImport, setOpenImport] = useState(false);
	const [importText, setImportText] = useState('');
	const [afterImport, setAfterImport] = useState(false);
	
	const formContext = useFormContext();
	let json = formContext.getValues("data");
	const jsonRef = useRef(null);
	
	const importTriggers = () => {
		try {
			let obj = JSON.parse(unescape(atob(importText)));
			setOpenImport(false);
			setAfterImport(true);
			setTimeout(() => { setAfterImport(false); }, 10);
			formContext.setValue("data", obj);
		}
		catch { alert('Invalid format, import aborted.'); }
	}

	return (!afterImport && <div className="auto-editor">
	<Stack spacing={2} direction="row" sx={{marginBottom: '20px'}}>
	<M_Button variant='outlined' onClick={()=>{setOpenExport(true); setTimeout(()=>{jsonRef.current.select()}, 10);}}>Export logic</M_Button>
	
	<M_Button variant='outlined' onClick={()=>{setImportText(''); setOpenImport(true);}}>Import logic</M_Button>
	</Stack>
	
	<RichSelectInput defaultValue={'daily'} validate={required()} label="Trigger" source="data.trigger" choices={autoTriggers}/>

	<AutoNestedComponent label="Main context" {...props} choices={entityOptions}
		defaultValue={'projects'} source="data"/>
	
	<Dialog open={openExport} onClose={()=>{setOpenExport(false)}} maxWidth='sm' fullWidth>
		<div>
			<DialogTitle id="alert-dialog-title">Export logic</DialogTitle>
			<DialogContent id="alert-dialog-title">
					<M_TextField inputRef={jsonRef} fullWidth multiline label="Trigger code" variant="outlined"
						value={btoa(escape(JSON.stringify(json)))} rows={20} />
			</DialogContent>
			<DialogActions>
				<M_Button onClick={()=>{ navigator.clipboard.writeText(btoa(escape(JSON.stringify(json)))); jsonRef.current.select(); }}>Copy to Clipboard</M_Button>
				<M_Button type="submit" onClick={()=>{setOpenExport(false)}}>Close</M_Button>
			</DialogActions>
		</div>
	</Dialog>
	
	<Dialog open={openImport} onClose={()=>{setOpenImport(false)}} maxWidth='sm' fullWidth>
		<div>
			<DialogTitle id="alert-dialog-title">Import logic</DialogTitle>
			<DialogContent id="alert-dialog-title">
				<M_TextField fullWidth id="outlined-basic" multiline label="Trigger code" variant="outlined"
						value={importText} onChange={(e) => { setImportText(e.target.value); }} rows={20} />
			</DialogContent>
			<DialogActions>				
				<M_Button type="submit" onClick={()=>{setOpenImport(false)}}>Cancel</M_Button>
				<M_Button variant="contained" type="submit" onClick={()=>{importTriggers();}}>Import</M_Button>
			</DialogActions>
		</div>
	</Dialog>
	
	</div>);
};

export default TriggerEditor;