import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Show,
  Filter,
  Pagination,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  Create,
  Edit,
  SimpleForm,
  BooleanField,
  BooleanInput,
  DateField,
  TextInput,
  required,
  ShowButton,
  RichTextField,
  useRecordContext
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { useState } from 'react';

const MailTempFilter = (props) => (
  <Filter {...props}>
	<TextInput source="__like__name" label="Name" alwaysOn />
  </Filter>
);

const MailTempPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 500]} {...props} />
);

const TemplateInput = (props) => {
	const record = useRecordContext();
	const [html, setHtml] = useState(record? record.template : null);	
	return <>
		<TextInput multiline {...props}
			onChange={(val) => { setHtml(val.target.value); }} />
		<h2>Preview:</h2>
			<div style={{ background: "white", color: "black"}} dangerouslySetInnerHTML={{__html : html}} >
		</div>
	</>
}

export const MailTempList = (props) => (
  <List
    {...props}
    filters={<MailTempFilter />}
    pagination={<MailTempPagination />}
    perPage={50}
  >
    <Datagrid>
      <TextField source="id" />
	  <TextField source="name" />
	  <TextField source="subject" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const MailTempCreate = (props) => {	
	return (<Create {...props} mutationMode="pessimistic" redirect="show">
		<SimpleForm>
			<TextInput source="name" validate={required()} />
			<TextInput source="subject" validate={required()} />
			<TemplateInput source="template" validate={required()} />
		</SimpleForm>
	</Create>)
};

export const MailTempEdit = (props) => (
	<Edit {...props} mutationMode="pessimistic" redirect="show">
		<SimpleForm>
			<TextInput disabled source="id" />
			<TextInput source="name" validate={required()} />
			<TextInput source="subject" validate={required()} />
			<TemplateInput source="template" validate={required()} />
		</SimpleForm>
	</Edit>
);

export const MailTempShow = (props) => (
	<Show {...props}>
		<SimpleShowLayout>
			<TextField source="id" />
			<TextField source="name" />
			<TextField source="subject" />
			<RichTextField source="template" />
		</SimpleShowLayout>
	</Show>
);