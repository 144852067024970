import React from 'react';
import {
  List, Datagrid, TextField, Edit,
  Show,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  SimpleShowLayout,
  Create,
  SimpleForm,
  TextInput,
  BooleanField,
  required,
  ShowButton,
  BooleanInput,
  SelectInput,
} from 'react-admin';

/*<ReferenceInput label="Name" source="id" reference="users" allowEmpty alwaysOn>
      <AutocompleteInput optionText="username" />
    </ReferenceInput>*/

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput source="__like__username" label="Username" alwaysOn />
	<SelectInput label="Enabled" alwaysOn source="enabled" choices={[
		{id : '1', name : 'Enabled'},
		{id : '0', name : 'Disabled'},
	]}/>
  </Filter>
);

export const UserList = (props) => (
  <List
    {...props}
    filters={<UserFilter />}
    >
    <Datagrid>
      <TextField source="id" />
      <TextField source="username" />
      <BooleanField source="enabled" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const UserEdit = (props) => (
  <Edit {...props} mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput disabled source="username" validate={required()} />
      <TextInput source="new-password" type="password" label="New password" />
	  <TextInput source="new-password-again" type="password" label="New password again" />
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Edit>
);

export const UserCreate = (props) => (
  <Create {...props} mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <TextInput source="username" validate={required()} />
      <TextInput source="password" type="password" validate={required()} />
      <TextInput source="password-again" label="Password again" type="password" validate={required()} />
      <BooleanInput source="enabled" defaultValue />
    </SimpleForm>
  </Create>
);

export const UserShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="username" />
      <BooleanField source="enabled" />
    </SimpleShowLayout>
  </Show>
);
