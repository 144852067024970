import React from 'react';
import {
  List, Datagrid, TextField, RichTextField, Edit,
  Show,
  EmailField,
  SimpleShowLayout,
  Create,
  SimpleForm,
  TextInput,
  ShowButton,
  ReferenceInput,
  AutocompleteInput,
  Filter,
  Pagination,
  required,
  TabbedForm
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { AutomationInput, AutomationField } from '../components/triggerEditor'

const ClientFilter = (props) => (
  <Filter {...props}>
	<TextInput source="__like__name" label="Name" alwaysOn />
	<TextInput source="__like__email" label="E-mail" alwaysOn />
  </Filter>
);

const ClientPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 500]} {...props} />
);

export const ClientList = (props) => (
  <List
    {...props}
    filters={<ClientFilter />}
    pagination={<ClientPagination />}
    perPage={50}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <EmailField source="email" label="E-mail" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const ClientEdit = (props) => (
  <Edit {...props} mutationMode="pessimistic" redirect="show">
    <TabbedForm>
		<TabbedForm.Tab label="Basic settings">
		  <TextInput disabled source="id" />
		  <TextInput source="name" validate={required()} />
		  <RichTextInput source="description" />
		  <TextInput source="email" label="E-mail" />
		</TabbedForm.Tab>
			
		<TabbedForm.Tab label="Automations">
			<AutomationInput type="clients" />
		</TabbedForm.Tab>
	</TabbedForm>
  </Edit>
);

export const ClientCreate = (props) => (
  <Create {...props} mutationMode="pessimistic" redirect="show">
     <TabbedForm>
		<TabbedForm.Tab label="Basic settings">
		  <TextInput source="name" validate={required()} />
		  <RichTextInput source="description" />
		  <TextInput source="email" label="E-mail" />
		</TabbedForm.Tab>
			
		<TabbedForm.Tab label="Automations">
			<AutomationInput type="clients" />
		</TabbedForm.Tab>
	</TabbedForm>
  </Create>
);

export const ClientShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <RichTextField source="description" />
      <EmailField source="email" />
	  <AutomationField label="Automations" type="clients" />
    </SimpleShowLayout>
  </Show>
);
