import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title } from 'react-admin';
import logo from '../logo.png';
import { useGetList } from 'react-admin';
import { Count, Link } from 'react-admin';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import EventNoteIcon from '@mui/icons-material/EventNote';
import BugReportIcon from '@mui/icons-material/BugReport';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const Dashboard = () => {
	
	const requests = useGetList(
		'requests',
		{ 
			pagination: { page: 1, perPage: 1 },
			sort:{ field: 'id', order: 'DESC' },
			filter: { handled: 0 }
		}
	);
	
	const crashes = useGetList(
		'crashes',
		{ 
			pagination: { page: 1, perPage: 1 },
			sort:{ field: 'id', order: 'DESC' }
		}
	);
	
	const logs = useGetList(
		'logs',
		{ 
			pagination: { page: 1, perPage: 1 },
			sort:{ field: 'id', order: 'DESC' }
		}
	);
	
	return (
	<Card>
		<Title title="Dashboard" />
		<CardContent className="doc">
		
			<img style={{float: 'right', height: '50px', margin: '10px'}} alt="Logo" src={logo} />
		
			<h1>Dashboard</h1>			
			
			<div>
			
			<Link className={requests.total?'pulsing-red': ''} to="/requests" sx={{ width: 400, display: "inline-block", margin: "5px" }}>
			  <Card sx={{ background: requests.total ? "rgba(211, 47, 47, 0.25)" : "rgba(46, 125, 50, 0.25)" }}>
			  <CardContent sx={{ position: "relative", zIndex: 0 }}>
				<span className="dashboard-card-backicon">
					<ChecklistRtlIcon />
				</span>
				<Typography sx={{ float: "right", marginTop: "-10px" }} variant="h2">
					<Count resource="requests" sx={{ fontSize: '3.75rem', color: requests.total ? "#ef5350" : "#4caf50" }} filter={{handled: 0}} />
				</Typography>
				<Typography variant="h5" color="text.primary" gutterBottom>
					License requests
				</Typography>
				<Typography sx={{ fontSize: 14 }} color="text.secondary">
					Pending requests for license
				</Typography>
				<div className="dashboard-card-bottom-panel">
					<Typography sx={{ fontSize: 12 }} color="text.secondary">
						{ requests.total ? (<>
							<WarningIcon fontSize="small" /> <span className="dashboard-card-bottom-text">Last licence request at { requests.data[0].created }</span>
						</>) : (<>
							<CheckIcon fontSize="small" /> <span className="dashboard-card-bottom-text">There are no pending requests</span>
						</>) }
					
					</Typography>
				</div>
			  </CardContent>
			</Card>
			</Link>
			
			<Link to="/crashes" sx={{ width: 400, display: "inline-block", margin: "5px" }}>
			  <Card sx={{ background: "rgba(255, 255, 255, 0.15)" }}>
			  <CardContent sx={{ position: "relative", zIndex: 0 }}>
				<span className="dashboard-card-backicon">
					<BugReportIcon />
				</span>
				<Typography sx={{ float: "right", marginTop: "-10px" }} variant="h2">
					<Count resource="crashes" sx={{ fontSize: '3.75rem' }} />
				</Typography>
				<Typography variant="h5" color="text.primary" gutterBottom>
					Crashes
				</Typography>
				<Typography sx={{ fontSize: 14 }} color="text.secondary">
					Collected crash reports
				</Typography>
				<div className="dashboard-card-bottom-panel">
					<Typography sx={{ fontSize: 12 }} color="text.secondary">
							<ScheduleIcon fontSize="small" /> <span className="dashboard-card-bottom-text">Last crash report at { crashes.data && crashes.data.length && crashes.data[0].created || "never" }</span>
					</Typography>
				</div>
			  </CardContent>
			</Card>
			</Link>
			
			<Link to="/logs" sx={{ width: 400, display: "inline-block", margin: "5px" }}>
			  <Card sx={{ background: "rgba(255, 255, 255, 0.15)" }}>
			  <CardContent sx={{ position: "relative", zIndex: 0 }}>
				<span className="dashboard-card-backicon">
					<EventNoteIcon />
				</span>
				<Typography sx={{ float: "right", marginTop: "-10px" }} variant="h2">
					<Count resource="logs" sx={{ fontSize: '3.75rem' }} />
				</Typography>
				<Typography variant="h5" color="text.primary" gutterBottom>
					Logs
				</Typography>
				<Typography sx={{ fontSize: 14 }} color="text.secondary">
					Collected logs and other reports
				</Typography>
				<div className="dashboard-card-bottom-panel">
					<Typography sx={{ fontSize: 12 }} color="text.secondary">
							<ScheduleIcon fontSize="small" /> <span className="dashboard-card-bottom-text">Last log report at { logs.data && logs.data.length && logs.data[0].created || "never" }</span>
					</Typography>
				</div>
			  </CardContent>
			</Card>
			</Link>
			
			<Link to="/licenses" sx={{ width: 810, display: "inline-block", margin: "5px" }}>
			  <Card sx={{ background: "rgba(255, 255, 255, 0.15)" }}>
			  <CardContent sx={{ position: "relative", zIndex: 0 }}>
				<span className="dashboard-card-backicon">
					<AttachFileIcon />
				</span>
				<Typography variant="h5" color="text.primary" gutterBottom>
					Licenses
				</Typography>
				<Typography sx={{ fontSize: 14 }} color="text.secondary">
					Imagine some really cool graphs and statistics here.<br /><br /><br />
					And here too.<br /><br /><br />
					And maybe also here.
				</Typography>
			  </CardContent>
			</Card>
			</Link>
			
			</div>
			
        </CardContent>
    </Card>
)}; 

export default Dashboard;