import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Show,
  Filter,
  Pagination,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  Create,
  Edit,
  SimpleForm,
  BooleanField,
  BooleanInput,
  DateField,
  TextInput,
  required,
  ShowButton,
  RichTextField
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';

const TagFilter = (props) => (
  <Filter {...props}>
	<TextInput source="__like__tag" label="Tag" alwaysOn />
  </Filter>
);

const TagPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 500]} {...props} />
);

export const TagList = (props) => (
  <List
    {...props}
    filters={<TagFilter />}
    pagination={<TagPagination />}
    perPage={50}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="tag" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const TagCreate = (props) => (
	<Create {...props} mutationMode="pessimistic" redirect="show">
		<SimpleForm>
			<TextInput source="tag" validate={required()} />
		</SimpleForm>
	</Create>
);

export const TagEdit = (props) => (
	<Edit {...props} mutationMode="pessimistic" redirect="show">
		<SimpleForm>
			<TextInput disabled source="id" />
			<TextInput source="tag" validate={required()} />
		</SimpleForm>
	</Edit>
);

export const TagShow = (props) => (
	<Show {...props}>
		<SimpleShowLayout>
			<TextField source="id" />
			<TextField source="tag" />
		</SimpleShowLayout>
	</Show>
);