import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Show,
  Filter,
  Pagination,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  Create,
  Edit,
  SimpleForm,
  BooleanField,
  BooleanInput,
  DateField,
  TextInput,
  required,
  ShowButton,
  RichTextField,
  TabbedForm,
  SelectField,
  ReferenceArrayInput,
  SimpleFormIterator,
  ArrayInput,
  Button,
  ArrayField,
  WithListContext,
  ListBase,
  SingleFieldList,
  useRecordContext,
  AutocompleteInput,
  Form,
  NumberInput
} from 'react-admin';
import { useState, useEffect, cloneElement } from 'react';
import { RichTextInput } from 'ra-input-rich-text';
import Grid from '@mui/material/Grid';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { AutomationInput, AutomationField } from '../components/triggerEditor'

const ProjectFilter = (props) => (
  <Filter {...props}>
	<TextInput source="__like__name" label="Name" alwaysOn />
	<ReferenceInput label="Product" source="fk_product" reference="products" allowEmpty alwaysOn>
      <SelectInput label="Product" optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Client" source="fk_client" reference="clients" allowEmpty alwaysOn>
      <SelectInput label="Client" optionText="name" />
    </ReferenceInput>	
    <SelectInput label="Enabled" alwaysOn source="enabled" choices={[
		{id : '1', name : 'Enabled'},
		{id : '0', name : 'Disabled'},
	]}/>
  </Filter>
);

const ProjectPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 500]} {...props} />
);

export const ProjectList = (props) => (
  <List
    {...props}
    filters={<ProjectFilter />}
    pagination={<ProjectPagination />}
    perPage={50}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
	  <BooleanField source="enabled" />
	  <ReferenceField label="Product" reference="products" source="fk_product">
		 <TextField source="name" />
	  </ReferenceField>
	  <ReferenceField label="Client" reference="clients" source="fk_client">
		 <TextField source="name" />
	  </ReferenceField>	  	  
      <DateField source="created" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const ProjectCreate = (props) => {
	return (<Create {...props} mutationMode="pessimistic" redirect="show">
		<TabbedForm>
			<TabbedForm.Tab label="Basic settings">
				<TextInput source="name" validate={required()} />
				<BooleanInput source="enabled" defaultValue />
				<ReferenceInput reference="products" source="fk_product">
					<SelectInput label="Product" optionText="name" validate={required()} />
				</ReferenceInput>
				<ReferenceInput reference="clients" source="fk_client">
					<SelectInput label="Client" optionText="name" validate={required()} />
				</ReferenceInput>
				<RichTextInput source="description" />				
			</TabbedForm.Tab>
			
			<TabbedForm.Tab label="Integration">
				<br /><span>Rocket.Chat channel for automated messages: </span>
				<TextInput source="channel" />
			</TabbedForm.Tab>
			
			<TabbedForm.Tab label="Automations">
				<AutomationInput type="projects" />
			</TabbedForm.Tab>
			
		</TabbedForm>
	</Create>)
};

export const ProjectEdit = (props) => {
	return (<Edit {...props} mutationMode="pessimistic" redirect="show">
		<TabbedForm>
			<TabbedForm.Tab label="Basic settings">
				<TextInput disabled source="id" />
				<TextInput source="name" validate={required()} />
				<BooleanInput source="enabled" defaultValue />
				<ReferenceInput reference="products" source="fk_product">
					<SelectInput label="Product" optionText="name" validate={required()} />
				</ReferenceInput>
				<ReferenceInput reference="clients" source="fk_client">
					<SelectInput label="Client" optionText="name" validate={required()} />
				</ReferenceInput>
				<RichTextInput source="description" />				
			</TabbedForm.Tab>
			
			<TabbedForm.Tab label="Integration">
				<span>Rocket.Chat channel for automated messages: </span>
				<TextInput source="channel" />
			</TabbedForm.Tab>
			
			<TabbedForm.Tab label="Automations">
				<AutomationInput type="projects" />
			</TabbedForm.Tab>
			
		</TabbedForm>
	</Edit>);
};

export const ProjectShow = (props) => {
	
	return (<Show {...props}>
		<SimpleShowLayout>
			<Grid container spacing={2} className="hide-sublabel">
				<Grid item md={4}>
					<span className="field-label">Name</span>
					<TextField className="field-value" source="name" />
				</Grid>
				
				<Grid item md={4}>
					<span className="field-label">Product</span>
					<div className="field-value">
						<ReferenceField label="Product" reference="products" source="fk_product">
							<TextField source="name" />
						</ReferenceField>
					</div>
				</Grid>
				
				<Grid item md={4}>
					<span className="field-label">Client</span>
					<div className="field-value">
						<ReferenceField label="Client" reference="clients" source="fk_client">
							<TextField source="name" />
						</ReferenceField>
					</div>
				</Grid>
			</Grid>
			
			<Grid container spacing={2} className="hide-sublabel">
				<Grid item md={4}>
					<span className="field-label">Created</span>
					<DateField disabled source="created" className="field-value" />
				</Grid>
				
				<Grid item md={4}>
					<span className="field-label">Enabled</span>
					<BooleanField source="enabled" className="field-value" />
				</Grid>
				
				<Grid item md={4}>
					<span className="field-label">ID</span>
					<TextField source="id" className="field-value" />
				</Grid>
			</Grid>
			
			<RichTextField source="description" />
			
			<TextField source="channel" />
			
			<AutomationField label="Automations" type="projects" />
		</SimpleShowLayout>
	</Show>)
};