import React from 'react';
import {
  List, Datagrid, TextField, RichTextField, Edit,
  Show,
  EmailField,
  SimpleShowLayout,
  Create,
  SimpleForm,
  TextInput,
  ShowButton,
  ReferenceInput,
  AutocompleteInput,
  Filter,
  Pagination,
  useRecordContext,
  required,
  SaveButton,
  DeleteButton,
  Toolbar
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import TriggerEditor from '../components/triggerEditor';

const AutomationFilter = (props) => (
  <Filter {...props}>
	<TextInput source="__like__name" label="Name" alwaysOn />
  </Filter>
);

const AutomationPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 500]} {...props} />
);

export const AutomationList = (props) => (
  <List
    {...props}
    filters={<AutomationFilter />}
    pagination={<AutomationPagination />}
    perPage={50}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const AutomationEdit = (props) => (
  <Edit {...props} mutationMode="pessimistic" redirect="show">
        <SimpleForm toolbar={
            <Toolbar {...props} sx={{ justifyContent: "space-between" }}>
                <SaveButton alwaysEnable />
                <div></div>
                <DeleteButton />
            </Toolbar>
        }>
      <TextInput disabled source="id" />
      <TextInput source="name" validate={required()} />
      <RichTextInput source="description" />
      <TriggerEditor />
    </SimpleForm>
  </Edit>
);

export const AutomationCreate = (props) => (
  <Create {...props} mutationMode="pessimistic" redirect="show">
        <SimpleForm toolbar={
            <Toolbar {...props} sx={{ justifyContent: "space-between" }}>
                <SaveButton alwaysEnable />
            </Toolbar>
        }>
      <TextInput source="name" validate={required()} />
      <RichTextInput source="description" />
      <TriggerEditor />
    </SimpleForm>
  </Create>
);

const printJsonTable = (obj) => {
	
	if (typeof(obj) === 'object')
		return <table className="auto-table-show"><tbody>
		{ obj && Object.keys(obj)?.map(key => {
			return <tr key={key}><td>{key}</td><td>{printJsonTable(obj[key])}</td></tr>
		}) }
		</tbody></table>
	else
		return <span>{obj+""}</span>
}

const DataField = (props) => {
	const record = useRecordContext();
	
	
	return (<pre>
		{ /*JSON.stringify(record.data, null, 3)*/ }
		{ printJsonTable(record.data) }
	</pre>);
}

export const AutomationShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <RichTextField source="description" />
	  <DataField label="Logic" />
    </SimpleShowLayout>
  </Show>
);
