import { React } from 'react';
import {
  List,
  Datagrid,
  TextField,
  Show,
  Filter,
  Pagination,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  BooleanInput,
  DateField,
  ShowButton,
  useRecordContext,
  TextInput,
  DateInput
} from 'react-admin';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import DangerousIcon from '@mui/icons-material/Dangerous';
import HelpIcon from '@mui/icons-material/Help';
import reactStringReplace from 'react-string-replace';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PreviewIcon from '@mui/icons-material/Preview';

const UNKNOWN = 0;
const INFO = 1;
const WARNING = 2;
const ERROR = 3;
const CRITICAL = 4;
const events = {
	[1] : { type : INFO, text: 'Server has started.' },
	[11] : { type : INFO, text: 'User has logged in.',
		data: { text: 'User: {{fk1}}', fk1: { resource: 'users', field: 'username'} }  },
	[12] : { type : INFO, text: 'User failed to log in.',
		data: { text: 'User: {{data}}' } },
		
	[21] : { type : INFO, text: 'Daily warden task has been run.',
		data: { text: 'Rutine: {{data}}' } },
	[22] : { type : INFO, text: 'Weekly warden task has been run.',
		data: { text: 'Rutine: {{data}}' } },
		
	[71] : { type : INFO, text: 'Entity created using API.',
		data: { text: 'Type: {{custom}}; Created by: {{fk1}}; Details: {{json}}', fk1: { resource: 'users', field: 'username'}, custom: (e) => <b>{JSON.parse(e.data).type}</b> } },
	[72] : { type : INFO, text: 'Entity patched using API.',
		data: { text: 'Type: {{custom}}; Patched by: {{fk1}}; Details: {{json}}', fk1: { resource: 'users', field: 'username'}, custom: (e) => <b>{JSON.parse(e.data).type}</b> } },
	[73] : { type : INFO, text: 'Entity updated using API.',
		data: { text: 'Type: {{custom}}; Updated by: {{fk1}}; Details: {{json}}', fk1: { resource: 'users', field: 'username'}, custom: (e) => <b>{JSON.parse(e.data).type}</b> } },
	[74] : { type : INFO, text: 'Entity removed using API.',
		data: { text: 'Type: {{custom}}; Removed by: {{fk1}}; Details: {{json}}', fk1: { resource: 'users', field: 'username'}, custom: (e) => <b>{JSON.parse(e.data).type}</b> } },
		
	[101] : { type : INFO, text: 'New license has been requested.',
		data: { text: 'Request details: {{json}}'} },
	[102] : { type : INFO, text: 'License request has been approved.',
		data: { text: 'Approved by: {{fk2}}; License: {{fk1}}; Details: {{json}}',
			fk2: { resource: 'users', field: 'username' }, fk1: { resource: 'licenses', field: 'identifier' } } },
	[103] : { type : INFO, text: 'License request has been dismissed.',
		data: { text: 'Dismissed by: {{fk2}}; License: {{fk1}}; Details: {{json}}',
			fk2: { resource: 'users', field: 'username' }, fk1: { resource: 'licenses', field: 'identifier' } } },
}

const HistoryFilter = (props) => (
   <Filter {...props}>
	<DateInput source="occured[$lt]" label="Occured before" />
	<DateInput source="occured[$gt]" label="Occured after" />
  </Filter>
);

const HistoryPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 500]} {...props} />
);

const EvTypeField = (props) => {
	const record = useRecordContext();
	let txt = 'Unknown event code: ' + record.type;
	let type = UNKNOWN;
	if (events[record.type]) {
		txt = events[record.type].text;
		type = events[record.type].type;
	}
		
	return (<span className={'event-log-row flex type-' + type}>
	{type == UNKNOWN && <HelpIcon fontSize="small" />}
	{type == INFO && <InfoIcon fontSize="small" />}
	{type == WARNING && <WarningIcon fontSize="small" />}
	{type == ERROR && <ErrorIcon fontSize="small" />}
	{type == CRITICAL && <DangerousIcon fontSize="small" />}
	&nbsp;<span>{txt}</span>
	</span>);
}

const EvDescField = (props) => {
	const record = useRecordContext();	
	let type = UNKNOWN;
	let dat = null;
	if (events[record.type]) {
		type = events[record.type].type;
		dat = events[record.type].data;
	}
	else return <TextField></TextField>;
	
	if (!dat) return <TextField></TextField>;
	
	let cont = reactStringReplace(dat.text, '{{fk1}}', (match, i) => {
		if (dat.fk1) {
			if (record.fk1 > 0)
				return (<ReferenceField reference={dat.fk1.resource} source="fk1">
					<TextField source={dat.fk1.field} />
				</ReferenceField>);
			else return '<no entity>';
		} else return record.fk1;
	});
	
	cont = reactStringReplace(cont, '{{fk2}}', (match, i) => {
		if (dat.fk2) {
			if (record.fk2 > 0)
				return (<ReferenceField reference={dat.fk2.resource} source="fk2">
					<TextField source={dat.fk2.field} />
				</ReferenceField>);
			else return '<no entity>';
		} else return record.fk2;
	});
	
	cont = reactStringReplace(cont, '{{data}}', (match, i) => {
		if (dat.data) {
			return (<ReferenceField reference={dat.data.resource} source="data">
				<TextField source={dat.data.field} />
			</ReferenceField>);
		} else return record.data;
	});
	
	cont = reactStringReplace(cont, '{{custom}}', (match, i) => {
		if (dat.custom) {
			return dat.custom(record);
		} else return '?';
	});
	
	cont = reactStringReplace(cont, '{{json}}', (match, i) => {
		let j = record.data;
		try {
			j = JSON.parse(record.data);
			j = JSON.stringify(j, null, 4);
		} catch {
			j = record.data;
		}
		
		return (<Tooltip arrow title={ <pre style={{overflow: 'hidden'}}>{j}</pre> } placement="top">
			<Link to="#"><PreviewIcon fontSize="small"></PreviewIcon></Link>
		</Tooltip>);
	});
	
	return (<span className={'event-log-row type-' + type}>
		{cont}
	</span>);
}

export const HistoryList = (props) => (
  <List
    {...props}
    filters={<HistoryFilter />}
    pagination={<HistoryPagination />}
    perPage={50}
  >
    <Datagrid>
	  <DateField source="occured" showTime />
      <EvTypeField label="Event" />
	  <EvDescField label="Data" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const HistoryShow = (props) => (
	<Show {...props}>
		<SimpleShowLayout>
			<DateField source="occured" showTime />
			<EvTypeField label="Event" />
			<EvDescField label="Data" />
			<h2>Raw data follows:</h2>
			<TextField source="type" />
			<TextField source="fk1" />
			<TextField source="fk2" />
			<TextField source="data" />
		</SimpleShowLayout>
	</Show>
);