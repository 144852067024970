import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Show,
  Filter,
  Pagination,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  AutocompleteInput,
  Create,
  Edit,
  SimpleForm,
  BooleanField,
  BooleanInput,
  DateField,
  TextInput,
  required,
  ShowButton,
  PasswordInput,
  DateTimeInput,
  useRecordContext,
  ReferenceArrayField,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SingleFieldList,
  ChipField,
  Loading,
  Error,
  useDataProvider,
  FormDataConsumer,
  useReference,
  DateInput,
  TabbedForm, Link
} from 'react-admin';
import { useCreate, useCreateSuggestionContext } from 'react-admin';
import { useForm, useFormContext } from "react-hook-form"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
} from '@mui/material';
import { TextField as MUITextField} from '@mui/material';
import Grid from '@mui/material/Grid';
import { useRefresh } from 'react-admin';
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import AssignmentIcon from '@mui/icons-material/Assignment';
import WarningIcon from '@mui/icons-material/Warning';
import { useState, useEffect } from 'react';
import { useField } from 'react-final-form';
import { AutomationInput, AutomationField } from '../components/triggerEditor'
import ScheduleIcon from '@mui/icons-material/Schedule';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import LabelIcon from '@mui/icons-material/Label';
import LabelOffIcon from '@mui/icons-material/LabelOff';
import TodayIcon from '@mui/icons-material/Today';
import StarIcon from '@mui/icons-material/Star';
import { useGetList } from 'react-admin';

import { ProjectInput, ProjectField } from '../components/projectInput';


const CreateTag = () => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [value, setValue] = React.useState(filter || '');
	const [create] = useCreate();
	const refresh = useRefresh();

    const handleSubmit = event => {
        event.preventDefault();
        create(
            'tags',
            {
                data: {
                    tag: value,
                },                
            },
            {
                onSuccess: (data) => {
                    setValue('');
                    onCreate(data);
					refresh();
                },
            }
        );
    };

    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <MUITextField
                        label="Tag"
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>                    
                    <Button onClick={onCancel}>Cancel</Button>
					<Button type="submit">Create tag</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const EXPIRING_DAYS = 30;

const licenseColors = {
  valid: 'rgba(158, 255, 143, 0.25) !important',
  expire_soon: 'rgba(255, 239, 99, 0.25) !important',
  expired: 'rgba(255, 99, 99, 0.25) !important',
  disabled: 'rgba(170, 170, 170, 0.25) !important',
};

function getLicenseStyleBackground(record)
{
	const expireSoon = new Date();
	const now = new Date();
	expireSoon.setDate(now.getDate() + EXPIRING_DAYS);
	
	const licenseDate = new Date(record['expires']);

	if (!record['enabled'])
		return licenseColors.disabled;
	if (licenseDate > expireSoon)
		return licenseColors.valid;
	if (licenseDate > now && licenseDate < expireSoon)
		return licenseColors.expire_soon;
	return licenseColors.expired;
}

/*const licenseStyles = makeStyles({
  valid: { background: '#9eff8f' },
  expire_soon: { background: '#ffef63' },
  expired: { background: '#ff8ea3' },
  disabled: { background: '#aaaaaa' },
});*/

const ColoredDateField = (props) => {
  //const classes = licenseStyles;
  const record = useRecordContext();
  return (
    <DateField
	  sx={{ background: getLicenseStyleBackground(record) }}
      showTime
      {...props}
    />
  );
};

const LicenseFilter = (props) => (
  <Filter {...props}>
  
	<TextInput label="Identifier" source="__like__identifier" alwaysOn />
  
	<ProjectInput source="fk_project" alwaysOn filterMode />	
	
	<ReferenceArrayInput label="Tags" reference="tags" alwaysOn source="__mn__licensestags__license_id__tag_id">
		<AutocompleteArrayInput optionText="tag" label="Tags" filterToQuery={(q) => {return {'__like__tag': q}}} />
	</ReferenceArrayInput>
	
	<TextInput label="Hardware Identity" source="__like__hwidentity" />
	
	<SelectInput label="Enabled" source="enabled" choices={[
		{id : '1', name : 'Enabled'},
		{id : '0', name : 'Disabled'},
	]}/>
	
	<DateInput source="expires[$lt]" label="Expires before" />
	<DateInput source="expires[$gt]" label = "Expires after" />
	<DateInput source="extended[$lt]" label = "Extended before" />
	<DateInput source="extended[$gt]" label = "Extended after" />

	<ReferenceArrayInput label="Not tags" reference="tags" source="__nmn__licensestags__license_id__tag_id">
		<AutocompleteArrayInput optionText="tag" label="Not tags" filterToQuery={(q) => {return {'__like__tag': q}}} />
	</ReferenceArrayInput>
  </Filter>
);

const LicensePagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 500]} {...props} />
);

const TokenField = (props) => {
	const record = useRecordContext();
	return(<div className="license-box">
		<span className="license-cool-icon"><AssignmentIcon fontSize="large" /></span>
		<div className="license-tools">
		<Button variant="contained" startIcon={<ContentCopyIcon />} label="Copy license to clipboard" onClick={()=>{navigator.clipboard.writeText(record["token"]);}}>Copy license to clipboard</Button>
		<Button variant="contained" startIcon={<SaveAltIcon />} label="Save license to file" onClick={()=>{
			const link = document.createElement("a");
			const file = new Blob([record["token"]], { type: 'application/experimental' });
			link.href = URL.createObjectURL(file);
			link.download = "License " + record["identifier"] + ".txt";
			link.click();
			URL.revokeObjectURL(link.href);
		}}>Save license to file</Button>
		</div>
		<div>{ record["token"] }</div>
	</div>)
}


export const LicenseList = (props) => {
	const now = new Date();
	var thisMonth = "/licenses?filter=%7B%22extended%22%3A%7B%22%24lt%22%3A%22[[BEFORE]]%22%2C%22%24gt%22%3A%22[[AFTER]]%22%7D%7D";
	thisMonth = thisMonth.replace("[[AFTER]]", now.getFullYear() + "-" + ((now.getMonth() + 1) + "").padStart(2, "0") + "-01");
	thisMonth = thisMonth.replace("[[BEFORE]]", now.getFullYear() + "-" + ((now.getMonth() + 2) + "").padStart(2, "0") + "-01");

	const { data, isLoading, error } = useGetList('constants', { filter : { key: 'EXKIWI_LICENSE_TAG_ID' } });

	const [extTagId, setExtTagId] = React.useState(-1);
	useEffect(() => {
		if (data && data.length && data.length === 1)
		{
			setExtTagId(data[0].value);
		}
	  }, [data]);

	var kiwi = "/licenses?filter=%7B%22extended%22%3A%7B%22%24gt%22%3A%221900-01-01%22%7D%2C%22__[[MN]]__licensestags__license_id__tag_id%22%3A%5B[[EXTENDED_TAG_ID]]%5D%7D";
	kiwi = kiwi.replace("[[EXTENDED_TAG_ID]]", extTagId.toString());
	var exKiwi = kiwi.replace("[[MN]]", "nmn");
	var notExKiwi = kiwi.replace("[[MN]]", "mn");
	
	return <><div className="filter-big-buttons" >
			<span>Shortcuts:</span>
			<Link to="/licenses?filter=%7B%7D"><Button variant="outlined" startIcon={<StarIcon />}>Clean filters</Button></Link>
			<Link to={thisMonth}><Button variant="outlined" startIcon={<TodayIcon />}>Extended this month</Button></Link>
			<Link to={exKiwi}><Button variant="outlined" startIcon={<LabelIcon />}>Extended ExKiwi</Button></Link>
			<Link to={notExKiwi}><Button variant="outlined" startIcon={<LabelOffIcon />}>Extended not ExKiwi</Button></Link>
		</div><List
		{...props}
		filters={<LicenseFilter />}
		pagination={<LicensePagination />}
		perPage={50}
	>
	<Datagrid>
      <TextField source="id" />
	  <BooleanField source="enabled" />
	  
	  <ProjectField source="fk_project" label="Project" />
	  
	  <TextField source="identifier" />
	  <TextField label="Hardware Identity" source="hwidentity" />
      <ReferenceArrayField label="Tags" source="tag_ids" reference="tags">
			<SingleFieldList linkType={false}>
				<ChipField source="tag" size="small" />
			</SingleFieldList>
	  </ReferenceArrayField>
	<DateField source="created" />
	<DateField source="extended" />
     <ColoredDateField source="expires" />
      <ShowButton />
    </Datagrid>
  </List></>
};



export const LicenseCreate = (props) => (
  <Create {...props} mutationMode="pessimistic" redirect="show">
    <TabbedForm>
		<TabbedForm.Tab label="Basic settings">     
		  <ProjectInput source="fk_project" />
		  <TextInput source="identifier" validate={required()} />
		  <TextInput label="Hardware Identity" source="hwidentity" validate={required()} />
		  <ReferenceArrayInput source="tag_ids" reference="tags">
			<AutocompleteArrayInput label="Tags" create={<CreateTag />} filterToQuery={(q) => {return {'__like__tag': q}}} optionText="tag" />
		  </ReferenceArrayInput>
		  <DateTimeInput source="expires" label="Expiration" validate={required()} options={{ format: 'dd/MM/yyyy, HH:mm:ss', ampm: false, clearable: true, }} />
		  <BooleanInput source="enabled" defaultValue />
		  <Typography component="span" sx={{"background" : "yellow !important", "color": "black",
					"fontSize" : "1.2em",
				"padding" : "10px"}}>Token will be generated when you save.</Typography><br />
    	</TabbedForm.Tab>
		
		<TabbedForm.Tab label="Automations">
			<AutomationInput type="licenses" />
		</TabbedForm.Tab>
	</TabbedForm>
  </Create>
);

export const LicenseEdit = (props) => (
  <Edit {...props} mutationMode="pessimistic" redirect="show">
    <TabbedForm>
		<TabbedForm.Tab label="Basic settings">   
		  <ProjectInput source="fk_project" />
		  <TextInput source="identifier" validate={required()} />
		  <TextInput label="Hardware Identity" source="hwidentity" validate={required()} />
		  <ReferenceArrayInput source="tag_ids" reference="tags">
			<AutocompleteArrayInput label="Tags" create={<CreateTag />} filterToQuery={(q) => {return {'__like__tag': q}}} optionText="tag" />
				</ReferenceArrayInput>
		  <DateTimeInput source="extended" label="Last extension" options={{ format: 'dd/MM/yyyy, HH:mm:ss', ampm: false, clearable: true, }} />
		  <DateTimeInput source="expires" label="Expiration" validate={required()} options={{ format: 'dd/MM/yyyy, HH:mm:ss', ampm: false, clearable: true, }} />
		  <BooleanInput source="enabled" />
		</TabbedForm.Tab>
		
		<TabbedForm.Tab label="Automations">
			<AutomationInput type="licenses" />
		</TabbedForm.Tab>
	</TabbedForm>
  </Edit>
);

export const LicenseShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
		<TextField source="identifier" className="big-value" />
	
		<Grid container spacing={2} className="hide-sublabel">
			<Grid item md={8}>
				<span className="field-label">Project</span>
				<div className="field-value">
					<ProjectField source="fk_project" />
				</div>
			</Grid>
			
			<Grid item md={2}>
				<span className="field-label">ID</span>
				<TextField className="field-value" source="id" />
			</Grid>

			<Grid item md={2}>
				<span className="field-label">Enabled</span>
				<BooleanField className="field-value" source="enabled" />
			</Grid>
		</Grid>
		
		<Grid container spacing={2} className="hide-sublabel">
			<Grid item md={4}>
				<span className="field-label">Created</span>
				<DateField source="created"  className="field-value" />
			</Grid>

			<Grid item md={4}>
				<span className="field-label">Last extension</span>
				<div className="field-value">
					<DateField source="extended" />
				</div>
			</Grid>

			<Grid item md={4}>
				<span className="field-label">Expires</span>
				<ColoredDateField source="expires"  className="field-value" />
			</Grid>
			
		</Grid>
		
		<Grid container spacing={2} className="hide-sublabel">
			
			<Grid item md={8}>
				<span className="field-label">Tags</span>
				<ReferenceArrayField label="Tags" className="field-value" source="tag_ids" reference="tags">
					<SingleFieldList linkType={false}>
						<ChipField source="tag" size="small" />
					</SingleFieldList>
				</ReferenceArrayField>
			</Grid>
			
			<Grid item md={4}>
				<span className="field-label">Hardware identity</span>
				<TextField label="Hardware Identity" source="hwidentity" className="field-value" />
			</Grid>
		</Grid>
	  <TokenField source="token" />		
	  
	  <AutomationField label="Automations" type="licenses" />
	  
    </SimpleShowLayout>
  </Show>
);
