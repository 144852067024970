import { useCreateSuggestionContext } from 'react-admin';
import { useForm, useFormContext } from "react-hook-form";
import { useState, useEffect } from 'react';
import { useField } from 'react-final-form';
import {
	ReferenceInput,
	AutocompleteInput,
	Loading,
	Error,
	useDataProvider,
	FormDataConsumer,
	required,
	ReferenceField,
	TextField,
	useRecordContext,
	useReference
} from 'react-admin';

const ProjectData = { /* for static data */ }
export function grabData(dataProvider, resource, setFunction, setLoading, setError) {
	if (typeof ProjectData[resource] === 'undefined' && !ProjectData['loading_' + resource]) {
		ProjectData['loading_' + resource] = true;
		dataProvider
		  .getList(resource, {
			pagination: { page: 1, perPage: 10000 },
			sort: { field: "id", order: "DESC" },
			filter: {},
		  })
		  .then(({ data }) => {
			setFunction(data);
			setLoading(false);
			ProjectData[resource] = data;
			ProjectData['loading_' + resource] = false;
			if (ProjectData['funcs_' + resource])
				for (var i = 0; i < ProjectData['funcs_' + resource].length; i++) {
					ProjectData['funcs_' + resource][i].setFunction(data);
					ProjectData['funcs_' + resource][i].setLoading(false);
				}
				
		  })
		  .catch((error) => {
			setError(error);
			setLoading(false);
			if (ProjectData['funcs_' + resource])
				for (var i = 0; i < ProjectData['funcs_' + resource].length; i++) {
					ProjectData['funcs_' + resource][i].setError(error);
					ProjectData['funcs_' + resource][i].setLoading(false);
				}
		  });
	} else {
		if (ProjectData['loading_' + resource]) {
			// Still loading
			if (!ProjectData['funcs_' + resource])
				ProjectData['funcs_' + resource] = [];
			ProjectData['funcs_' + resource].push({setFunction, setLoading, setError});
		} else {
			// Already done
			setFunction(ProjectData[resource]);
			setLoading(false);
		}
	}
}

export const ProjectField = (props) => {
	
	const record = useRecordContext();
	
	const dataProvider = useDataProvider();
	const [clients, setClients] = useState();
	const [products, setProducts] = useState();
	const [loading1, setLoading1] = useState(true);
	const [loading2, setLoading2] = useState(true);
	const [error, setError] = useState();
	
	useEffect(() => { grabData(dataProvider, 'clients', setClients, setLoading1, setError); }, []);	
	useEffect(() => { grabData(dataProvider, 'products', setProducts, setLoading2, setError); }, []);
	
	const proj = useReference({ reference: 'projects', id: record.fk_project });
	
	if (loading1 || loading2 || proj.isLoading) return <></>;
	if (error) return <Error />
	
	return <>
		<ReferenceField label="Project" source="fk_project" reference="projects">
			<>
			<TextField source="name" label="Project" />
			<span style={{opacity: 0.6, color: 'white', fontSize: '0.875rem'}}> ({ products.filter(x => x.id == proj.referenceRecord.fk_product)[0].name + ', ' + clients.filter(x => x.id == proj.referenceRecord.fk_client)[0].name})</span>
			</>
		</ReferenceField>		
	</>
}

export const ProjectInput = (props) => {
	const dataProvider = useDataProvider();
	const [clients, setClients] = useState();
	const [products, setProducts] = useState();
	const [projects, setProjects] = useState();
	const [loading1, setLoading1] = useState(true);
	const [loading2, setLoading2] = useState(true);
	const [loading3, setLoading3] = useState(true);
	const [selectedProj, setSelectedProj] = useState();
	const [error, setError] = useState();
	
	useEffect(() => { grabData(dataProvider, 'clients', setClients, setLoading1, setError); }, []);	
	useEffect(() => { grabData(dataProvider, 'products', setProducts, setLoading2, setError); }, []);
	useEffect(() => { grabData(dataProvider, 'projects', setProjects, setLoading3, setError); }, []);
	
	const formContext = useFormContext();
	
	const projSelectHandle = (id) => {
		if (selectedProj != id && projects && clients && products)
		{
			if (id) {
				let proj = projects.filter(x => x.id == id)[0];
				if (formContext.getValues('__jf__fk_project__projects__fk_product') != proj.fk_product || 
					formContext.getValues('__jf__fk_project__projects__fk_client') != proj.fk_client)
				{
					formContext.setValue('__jf__fk_project__projects__fk_product', proj.fk_product);
					formContext.setValue('__jf__fk_project__projects__fk_client', proj.fk_client);
				}
			}
		}
	}
	
	const refreshProj = () => {
		let fid = formContext.getValues('fk_project');
		if (fid) {
			projSelectHandle(fid);
		}
	}
	
	// componentDidMount equivalent:
	useEffect(() => { refreshProj(); }, []);
	
	// And reacting to other state changes:
	useEffect(() => { refreshProj(); }, [projects]);
	useEffect(() => { refreshProj(); }, [products]);
	useEffect(() => { refreshProj(); }, [clients]);
	
	if (loading1 || loading2 || loading3) return <></>;
	if (error) return <Error />;
	
	return <div style={{display: 'flex', width: '100%'}}>

		<ReferenceInput source="__jf__fk_project__projects__fk_product" reference="products">
			<AutocompleteInput sx={{maxWidth: '250px'}} label={props.labelProduct || "Product"} optionText="name"
				filterToQuery={(q) => {return {'name[$like]': '%'+q+'%'}}}
				onChange={(id) => { formContext.setValue('fk_project', null); }} />
		</ReferenceInput>
		{ (props.filterMode !== true) && <span style={{margin: '20px'}}> / </span> }
		{ props.filterMode && <div className="RaFilterFormInput-spacer"></div> }
		<ReferenceInput source="__jf__fk_project__projects__fk_client" reference="clients">
			<AutocompleteInput sx={{maxWidth: '250px'}} label={props.labelClient || "Client"} optionText="name" 
				filterToQuery={(q) => {return {'name[$like]': '%'+q+'%'}}}
				onChange={(id) => { formContext.setValue('fk_project', null); }} />
		</ReferenceInput>
		{ (props.filterMode !== true) && <span style={{margin: '20px'}}> ➤ </span> }

		{ props.filterMode && <div className="RaFilterFormInput-spacer"></div> }
		<FormDataConsumer>
		{
			({ formData, ...rest }) => {
				const client_id = formData?.__jf__fk_project__projects__fk_client;
				const product_id = formData?.__jf__fk_project__projects__fk_product;
				let f = {}
				if (client_id) f.fk_client = client_id;
				if (product_id) f.fk_product = product_id;
				return <ReferenceInput filter={f} reference="projects" {...props}>
					<AutocompleteInput
						label="Project"
						optionText={(choice) => {
							return <span>
								<span style={{fontWeight: 'bold'}}>{choice.name}</span>
								<span style={{opacity: 0.6}}>{' (' + products.filter(x => x.id == choice.fk_product)[0].name + ', ' +  clients.filter(x => x.id == choice.fk_client)[0].name + ')'}</span>
							</span>;
						}}						
						onChange={(id) => { projSelectHandle(id); }}
						inputText={(choice) => choice.name }
						validate={ !props.filterMode && required()}
						filterToQuery={(q) => {return {'name[$like]': '%'+q+'%'}}} />
				</ReferenceInput>
			}
		}			
		</FormDataConsumer>
	</div>
	
}