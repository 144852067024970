import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Show,
  Filter,
  Pagination,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  Create,
  Edit,
  SimpleForm,
  BooleanField,
  BooleanInput,
  DateField,
  TextInput,
  required,
  ShowButton,
  RichTextField,
  TabbedForm
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { AutomationInput, AutomationField } from '../components/triggerEditor'

const ProductFilter = (props) => (
  <Filter {...props}>    
	<TextInput source="__like__name" label="Name" alwaysOn />
    <SelectInput label="Enabled" alwaysOn source="enabled" choices={[
		{id : '1', name : 'Enabled'},
		{id : '0', name : 'Disabled'},
	]}/>
  </Filter>
);

const ProductPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 500]} {...props} />
);

export const ProductList = (props) => (
  <List
    {...props}
    filters={<ProductFilter />}
    pagination={<ProductPagination />}
    perPage={50}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
	  <BooleanField source="enabled" />
      <DateField source="created" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const ProductCreate = (props) => (
	<Create {...props} mutationMode="pessimistic" redirect="show">
		<TabbedForm>
		<TabbedForm.Tab label="Basic settings">
			<TextInput source="name" validate={required()} />
			<BooleanInput source="enabled" defaultValue />
			<RichTextInput source="description" />
		</TabbedForm.Tab>
		
		<TabbedForm.Tab label="Automations">
			<AutomationInput type="products" />
		</TabbedForm.Tab>
		</TabbedForm>
	</Create>
);

export const ProductEdit = (props) => (
	<Edit {...props} mutationMode="pessimistic" redirect="show">
		<TabbedForm>
		<TabbedForm.Tab label="Basic settings">
			<TextInput disabled source="id" />
			<TextInput source="name" validate={required()} />
			<BooleanInput source="enabled" defaultValue />
			<RichTextInput source="description" />
		</TabbedForm.Tab>
		
		<TabbedForm.Tab label="Automations">
			<AutomationInput type="products" />
		</TabbedForm.Tab>
		</TabbedForm>
	</Edit>
);

export const ProductShow = (props) => (
	<Show {...props}>
		<SimpleShowLayout>
			<TextField source="id" />
			<DateField disabled source="created" />
			<TextField source="name" />
			<BooleanField source="enabled" />
			<RichTextField source="description" />
			<AutomationField label="Automations" type="products" />
		</SimpleShowLayout>
	</Show>
);