import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Show,
  Filter,
  Pagination,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  Create,
  Edit,
  SimpleForm,
  BooleanField,
  BooleanInput,
  DateField,
  TextInput,
  required,
  ShowButton,
  RichTextField,
  Toolbar,
  SaveButton
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';

const ConstantFilter = (props) => (
  <Filter {...props}>    
    <TextInput source="__like__key" label="Key" alwaysOn />
  </Filter>
);

const ConstantPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 500]} {...props} />
);

const ConstantEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export const ConstantList = (props) => (
  <List
    {...props}
    filters={<ConstantFilter />}
    pagination={<ConstantPagination />}
    perPage={50}
	bulkActionButtons={false}
  >
    <Datagrid bulkActionButtons={false}>
		<TextField source="key" />
		<TextField sx={{textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    overflow: 'hidden',
    display: 'inline-block',
    width: '400px'}} source="value" multiline />
      <ShowButton />
    </Datagrid>
  </List>
);

export const ConstantCreate = (props) => (
	<Create {...props} mutationMode="pessimistic" redirect="show">
		<SimpleForm>
			<TextInput source="key" />
			<TextInput source="value" multiline  />
		</SimpleForm>
	</Create>
);

export const ConstantEdit = (props) => (
	<Edit {...props} mutationMode="pessimistic" redirect="show">
		<SimpleForm>
			<TextInput disabled source="key" />
			<TextInput source="value" multiline  />
		</SimpleForm>
	</Edit>
);

export const ConstantShow = (props) => (
	<Show {...props}>
		<SimpleShowLayout>
			<TextField source="key" />
			<TextField source="value" multiline  />
		</SimpleShowLayout>
	</Show>
);