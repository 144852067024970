import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Show,
  Filter,
  Pagination,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  Create,
  Edit,
  SimpleForm,
  BooleanField,
  BooleanInput,
  DateField,
  TextInput,
  required,
  ShowButton,
  RichTextField,
  CheckboxGroupInput,
  useRecordContext,  
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
	Chip
} from '@mui/material';
import Grid from '@mui/material/Grid';

import { ProjectInput, ProjectField } from '../components/projectInput';

const NotifBlacklistFilter = (props) => (
  <Filter {...props}>
	<ProjectInput source="fk_project" filterMode allowEmpty alwaysOn />
  </Filter>
);

const NotifBlacklistPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 500]} {...props} />
);

const ScopeField = (props) => {
  const record = useRecordContext();
  console.log(record);
  return <>{record['scope'].map && record['scope'].map(string => <Chip key={string} size="small" label={string.charAt(0).toUpperCase() + string.slice(1)} />)}</>

};

const NotifKeyInput = (props) => {
  const record = useRecordContext();
  return <SelectInput {...props} choices={[
		{ id: 'identity', name: 'Identity' },
		{ id: 'version', name: 'Version' },
		{ id: 'type', name: 'Type' },
		{ id: 'error', name: 'Error (only Crash Report)' },
		{ id: 'detail', name: 'Detail (only Crash Report)' },
		{ id: 'config', name: 'Config (only Crash Report)' },
		{ id: 'description', name: 'Description (only Crash Report)' },
		{ id: 'message', name: 'Message (only Log)' },
		{ id: 'data', name: 'Data (only Log)' },
	]} />
}

export const NotifBlacklistList = (props) => (
  <List
    {...props}
    filters={<NotifBlacklistFilter />}
    pagination={<NotifBlacklistPagination />}
    perPage={50}
  >
    <Datagrid>
      <TextField source="id" />
      <ProjectField label="Project" source="fk_project" />
	  <ScopeField label="Scope" source="scope" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const NotifBlacklistCreate = (props) => (
	<Create {...props} mutationMode="pessimistic" redirect="show">
		<SimpleForm>
			<ProjectInput label="Project" source="fk_project" />
			<CheckboxGroupInput source="scope" row={false} choices={[
				{ id: 'crashes', name: 'Crash Reports' },
				{ id: 'logs', name: 'Logs' }
			]} />
			
			<NotifKeyInput source="key1" label="Key 1" />
			<TextInput source="val1" label="Value 1" />
			<NotifKeyInput source="key2" label="Key 2" />
			<TextInput source="val2" label="Value 2" />
			<NotifKeyInput source="key3" label="Key 3" />
			<TextInput source="val3" label="Value 3" />
			<NotifKeyInput source="key4" label="Key 4" />
			<TextInput source="val4" label="Value 4" />
		</SimpleForm>
	</Create>
);

export const NotifBlacklistEdit = (props) => (
	<Edit {...props} mutationMode="pessimistic" redirect="show">
		<SimpleForm>
			<TextInput disabled source="id" />
			<ProjectInput label="Project" source="fk_project" />
			<CheckboxGroupInput source="scope" row={false} choices={[
				{ id: 'crashes', name: 'Crash Reports' },
				{ id: 'logs', name: 'Logs' }
			]} />
			<NotifKeyInput source="key1" label="Key 1" />
			<TextInput source="val1" label="Value 1" />
			<NotifKeyInput source="key2" label="Key 2" />
			<TextInput source="val2" label="Value 2" />
			<NotifKeyInput source="key3" label="Key 3" />
			<TextInput source="val3" label="Value 3" />
			<NotifKeyInput source="key4" label="Key 4" />
			<TextInput source="val4" label="Value 4" />
		</SimpleForm>
	</Edit>
);

export const NotifBlacklistShow = (props) => (
	<Show {...props}>
		<SimpleShowLayout>
			<Grid container spacing={2} className="hide-sublabel">
			
				<Grid item md={12}>
					<span className="field-label">Project</span>
					<div className="field-value">
						<ProjectField source="fk_project" />
					</div>
				</Grid>
				
				<Grid item md={8}>
					<span className="field-label">Scope</span>					
					<div className="field-value">
						<ScopeField />
					</div>
				</Grid>
				
				<Grid item md={4}>
					<span className="field-label">ID</span>
					<TextField source="id" className="field-value" />
				</Grid>
				
				<Grid item md={4}>
					<span className="field-label">Key 1</span>
					<TextField source="key1" className="field-value" />
				</Grid>
				<Grid item md={8}>
					<span className="field-label">Value 1</span>
					<TextField source="val1" className="field-value" />
				</Grid>
				
				<Grid item md={4}>
					<span className="field-label">Key 2</span>
					<TextField source="key2" className="field-value" />
				</Grid>
				<Grid item md={8}>
					<span className="field-label">Value 2</span>
					<TextField source="val2" className="field-value" />
				</Grid>
				
				<Grid item md={4}>
					<span className="field-label">Key 3</span>
					<TextField source="key3" className="field-value" />
				</Grid>
				<Grid item md={8}>
					<span className="field-label">Value 3</span>
					<TextField source="val3" className="field-value" />
				</Grid>
				
				<Grid item md={4}>
					<span className="field-label">Key 4</span>
					<TextField source="key4" className="field-value" />
				</Grid>
				<Grid item md={8}>
					<span className="field-label">Value 4</span>
					<TextField source="val4" className="field-value" />
				</Grid>
			
			</Grid>
		</SimpleShowLayout>
	</Show>
);