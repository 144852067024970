import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Show,
  Filter,
  Pagination,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  Create,
  Edit,
  SimpleForm,
  BooleanField,
  BooleanInput,
  DateField,
  DateInput,
  TextInput,
  required,
  ShowButton,
  CheckboxGroupInput,
  Link,
  useRecordContext,
  useDataProvider,
  Loading,
  ArrayField,
  SingleFieldList,
  TabbedForm,
} from 'react-admin';
import { AutomationInput, AutomationField } from '../components/triggerEditor'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
	Chip
} from '@mui/material';
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import AssignmentIcon from '@mui/icons-material/Assignment';
import WarningIcon from '@mui/icons-material/Warning';
import Grid from '@mui/material/Grid';
import { useState, useEffect } from 'react';

import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import CardContent from "@mui/material/CardContent";
import KeyboardArrowDownIcon from 
    "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from 
    "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";

import { ProjectInput, ProjectField } from '../components/projectInput';

const ApiKeyFilter = (props) => (
  <Filter {...props}>
    <ProjectInput source="fk_project" filterMode allowEmpty alwaysOn />
	<DateInput source="expires[$lt]" label="Expires before" />
	<DateInput source="expires[$gt]" label="Expires after" />
  </Filter>
);

const ApiKeyPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 500]} {...props} />
);

const EXPIRING_DAYS = 30;
const licenseColors = {
  valid: 'rgba(158, 255, 143, 0.25) !important',
  expire_soon: 'rgba(255, 239, 99, 0.25) !important',
  expired: 'rgba(255, 99, 99, 0.25) !important',
  disabled: 'rgba(170, 170, 170, 0.25) !important',
};

function getLicenseStyleBackground(record)
{
	const expireSoon = new Date();
	const now = new Date();
	expireSoon.setDate(now.getDate() + EXPIRING_DAYS);
	
	const licenseDate = new Date(record['expires']);

	/*if (!record['enabled'])
		return licenseColors.disabled;*/
	if (licenseDate > expireSoon)
		return licenseColors.valid;
	if (licenseDate > now && licenseDate < expireSoon)
		return licenseColors.expire_soon;
	return licenseColors.expired;
}

const ColoredDateField = (props) => {
  const record = useRecordContext();
  return (
    <DateField sx = {{ background: getLicenseStyleBackground(record) }}	 showTime {...props} />
  );
};

const ScopeField = (props) => {
  const record = useRecordContext();
  return <>{record['scope'].map && record['scope'].map(string => <Chip key={string} size="small" label={string.charAt(0).toUpperCase() + string.slice(1)} />)}</>

};

const TokenField = (props) => {
	const record = useRecordContext();
	return(<div className="license-box">
		<span className="license-cool-icon"><AssignmentIcon fontSize="large" /></span>
		<div className="license-tools">
		<Button variant="contained" startIcon={<ContentCopyIcon />} label="Copy key to clipboard" onClick={()=>{navigator.clipboard.writeText(record["token"]);}}>Copy key to clipboard</Button>
		<Button variant="contained" startIcon={<SaveAltIcon />} label="Save key to file" onClick={()=>{
			const link = document.createElement("a");
			const file = new Blob([record["token"]], { type: 'application/experimental' });
			link.href = URL.createObjectURL(file);
			link.download = "License " + record["identifier"] + ".txt";
			link.click();
			URL.revokeObjectURL(link.href);
		}}>Save key to file</Button>
		</div>
		<div>{ record["token"] }</div>
	</div>)
}

export const ApiKeyList = (props) => {
	
  return <List
    {...props}
    filters={<ApiKeyFilter />}
    pagination={<ApiKeyPagination />}
    perPage={50}
  >
    <Datagrid>
      <TextField source="id" />
      <ProjectField label="Project" source="fk_project" />
      <DateField source="created" />
	  <ScopeField label="Scope" source="scope" />
	  <ColoredDateField source="expires" />
	  <BooleanField source="dynamic" defaultValue={false} />
      <ShowButton />
    </Datagrid>
  </List>
};

export const ApiKeyCreate = (props) => (
	<Create {...props} mutationMode="pessimistic" redirect="show">
		<TabbedForm>
			<TabbedForm.Tab label="Basic settings">
				<ProjectInput label="Project" source="fk_project" />
				<DateInput source="expires" validate={required()} />
				<CheckboxGroupInput source="scope" row={false} choices={[
					{ id: 'crashes', name: 'Crash Reports' },
					{ id: 'logs', name: 'Logs' },
					{ id: 'credentials', name: 'Credentials' },
					{ id: 'requests', name: 'License Requests' },
				]} />			
				<TextField source="token" />
				<Typography component="span" sx={{"background" : "yellow !important", "color": "black",
					"font-size" : "1.2em",
				"padding" : "10px"}}>Token will be generated when you save.</Typography><br />
				<TextInput source="secret" label="Public secret (for credentials)" />
				<BooleanInput source="dynamic" label="Dynamic (experimental)" defaultValue={false} />
				<DynamicTokenInfo className="hide-sublabel" />
			</TabbedForm.Tab>
			
			<TabbedForm.Tab label="Automations">
				<AutomationInput type="apikeys" />
			</TabbedForm.Tab>
			
		</TabbedForm>
	</Create>
);

export const ApiKeyEdit = (props) => (
	<Edit {...props} mutationMode="pessimistic" redirect="show">
		<TabbedForm>
			<TabbedForm.Tab label="Basic settings">
				<TextInput disabled source="id" />
				<ProjectInput label="Project" source="fk_project" />
				<DateInput source="expires" validate={required()} />
				<CheckboxGroupInput source="scope" row={false} choices={[
					{ id: 'crashes', name: 'Crash Reports' },
					{ id: 'logs', name: 'Logs' },
					{ id: 'credentials', name: 'Credentials' },
					{ id: 'requests', name: 'License Requests' },
				]} />
				<TextInput source="secret" label="Public secret (for credentials)" />
				<BooleanInput source="dynamic" label="Dynamic (experimental)" defaultValue={false} />
				<DynamicTokenInfo className="hide-sublabel" />
			</TabbedForm.Tab>
			
			<TabbedForm.Tab label="Automations">
				<AutomationInput type="apikeys" />
			</TabbedForm.Tab>
			
		</TabbedForm>
	</Edit>
);

export const ApiKeyShow = (props) => {
	
	return <Show {...props}>
		<SimpleShowLayout>
		
			<Grid container spacing={2} className="hide-sublabel">
				<Grid item md={4}>
					<span className="field-label">Project</span>
					<div className="field-value">
						<ProjectField source="fk_project" />
					</div>
				</Grid>
				
				<Grid item md={4}>
					<span className="field-label">Scope</span>					
					<div className="field-value">
						<ScopeField />
					</div>
				</Grid>
				
				<Grid item md={4}>
					<span className="field-label">ID</span>
					<TextField source="id" className="field-value" />
				</Grid>
			</Grid>
			
			<Grid container spacing={2} className="hide-sublabel">
				<Grid item md={4}>
					<span className="field-label">Created</span>
					<DateField source="created" className="field-value" />
				</Grid>
				
				<Grid item md={4}>
					<span className="field-label">Expires</span>
					<ColoredDateField source="expires" className="field-value" />
				</Grid>
				
				<Grid item md={4}>
					<span className="field-label">Public secret (for credentials)</span>
					<TextField source="secret" className="field-value" />
				</Grid>
			</Grid>
		
			<TokenField source="token" />
			
			<AutomationField label="Automations" type="___" />
			
			<BooleanField source="dynamic" defaultValue={false} />
			<DynamicTokenInfo className="hide-sublabel" />
		</SimpleShowLayout>
	</Show>
};

const DynamicTokenInfo = (props) => {
	
	const [open, setOpen] = useState(false);

	return <Card><Button variant="outlined"
			onClick={() => setOpen(!open)}>
				More info about dynamic tokens
					{open ? <KeyboardArrowUpIcon />
						: <KeyboardArrowDownIcon />}
			
		</Button> <Collapse in={open} timeout="auto" {...props}>
	
	<div sx={{"background" : "rgba(0,0,0,0.1)", padding: '10px', width: "auto"}}>
	<p>Dynamic tokens provide an extra layer of security. When enabled, instead of sending the base token in it's pure form, clients need to generate JWT token in this format:</p><pre>
	&#123;
		'token' : '&lt;base token&gt;',
		'expires' : '&lt;choose your date and time&gt;'
	&#125;
	</pre>
	<p>
	Algorithm is HS256 and as the secret, use the SECRET_DYNAMIC_API_KEY from settings.
	</p>
	<p>Example of token with expiration of "1993-05-10T23:59:59":</p>
	<pre style={{
		"maxWidth" : "500px",
		"overflowX" : "auto",
		"whiteSpace" : "pre-wrap",
		"wordWrap": "break-word"
	}}>
	eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6ImJjNzFlNjdmZjgxZDBjODY3ZTY0MDA3NTY3NzZmZjM3YzRiYWE1OWRiZjlmNzI1ZDQ3MjM1ZTQ2NGEzYTMwYWFkOWE2MjU3NjFlMzZiZjJmODhmYzkyY2ZhZGE2YWUwNzM3NGRmZWYyMjUxZjdlMWQzZjgzOTNjNiIsImV4cGlyZXMiOiIxOTkzLTA1LTEwVDIzOjU5OjU5In0.ktBT3hEfH232qthL5sgYKRSc_kGSxfEBcQsTVShSF-M
	</pre>
	<p>You can try to decode it here: <Link href="https://jwt.io/">https://jwt.io/</Link></p>
	<p>This can ensure that a stolen token will expire on it's own because it's ever changing. You can even change it every minute or so. Client chooses the expiration time, server only does the check against it.</p>
	<p>Both client and server know the secret in this case - they are considered to be safe while the transfer medium is not.</p>
	<p>You can still rewoke the base token when using this technique. That still works just like normal API token.</p>
	</div>
	
	</Collapse></Card>
}